export const usefileDownload = () => {
  const downloadFile = (blob: Blob, filename: string) => {
    const downloadUrl = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', filename)

    // Simulate a click to download
    link.click()

    // Release resources
    window.URL.revokeObjectURL(downloadUrl)
  }

  return {
    downloadFile,
  }
}
