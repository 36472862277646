import { FC, SVGProps } from 'react'

import Style from '../styles/AppTile.module.sass'
import classnames from 'classnames'

interface IAppTileProps {
  AppIcon: FC<SVGProps<SVGSVGElement>> | null
  content: string
  onClick: () => void
  cssClass?: string
}

export const AppTile: FC<IAppTileProps> = (props) => {
  const { AppIcon, content, cssClass, onClick } = props

  return (
    <div className={Style.tile} onClick={onClick} role='none'>
      <div>
        {AppIcon && (
          <AppIcon
            role='image'
            aria-label={content}
            className={classnames(Style.logo, { [`${cssClass}`]: !!cssClass })}
          />
        )}
      </div>
      <div className={Style.content}>{content}</div>
    </div>
  )
}
