import { FC, useState } from 'react'
import { Grid, Icon } from '@aurecon-creative-technologies/styleguide'
import { NomicDataSourceEnum } from '../../enums/BambooPropertyEnum'
import LimitedCharactersLeft from '../common/LimitedCharacters'
import RecallModal from '../common/RecallModal'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import RecallFormInput from '../common/RecallFormInput'
import RecallDropdown from '../common/RecallDropdown'
import { v4 } from 'uuid'
import { getErrorsFromValidationResult, IErrorModel } from '../../validators/commonValidator'
import { FullScreen } from '../../stores/AppStore'
import { useRecoilValue } from 'recoil'

import Style from '../../styles/NomicModal.module.sass'
import { INomicModel } from '../../models/INomicModel'
import { NomicPropertyModalFields, nomicPropertyModalSchema } from '../../validators/nomicPropertyModalValidator'
import { DefaultNomicProperty } from '../../pages/CustomRecallAppHome'

import { ReactComponent as NomicImageLeft } from '../../assets/nomic_1.svg'
import { ReactComponent as NomicImageRight } from '../../assets/nomic_2.svg'
import { useLanguages } from '../../hooks/useLanguages'
import { Trans } from 'react-i18next'

interface INomicModalProps {
  open: boolean
  nomicPropertyModel: INomicModel
  onSave: (data: INomicModel) => Promise<void>
  onClose: () => void
  loading?: boolean
}

const NomicModal: FC<INomicModalProps> = (props) => {
  const { open, nomicPropertyModel, onSave, onClose, loading } = props
  const [nomicProperty, setNomicProperty] = useState<INomicModel>({
    ...nomicPropertyModel,
    dataSource: NomicDataSourceEnum.EXISTING_NOMIC,
  })
  const [errors, setErrors] = useState<IErrorModel>({})
  const fullScreen = useRecoilValue(FullScreen)
  const { t } = useLanguages()

  const handleModalClose = () => {
    setNomicProperty(DefaultNomicProperty)
    setErrors({})
    onClose()
  }

  const validateNomicProperty = (currentProperty: INomicModel) => {
    const validationResult = nomicPropertyModalSchema().validate(
      { dataSource: currentProperty.dataSource, appName: currentProperty.appName, nomicMap: currentProperty.nomicMap },
      { abortEarly: false },
    )
    return getErrorsFromValidationResult(validationResult)
  }

  const handleValueChange = (field: string, value: string | number, limit?: number) => {
    let val = typeof value === 'string' ? value.substring(0, limit) : value

    if (field === NomicPropertyModalFields.nomicMap && typeof val === 'string') {
      const hashIndex = val.indexOf('#')
      val = hashIndex !== -1 ? val.slice(0, hashIndex) : val
    }

    const updatedProperty = { ...nomicProperty, [field]: val }
    setNomicProperty(updatedProperty)
    const errors = validateNomicProperty(updatedProperty)
    setErrors(errors)
  }

  const handleSaveProperty = async () => {
    const errors = validateNomicProperty(nomicProperty)
    if (Object.keys(errors).length) {
      setErrors(errors)
      return
    }

    await onSave({
      ...nomicProperty,
      id: nomicProperty.id || v4(),
    })

    handleModalClose()
  }

  return (
    <RecallModal
      chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
      isShowing={open}
      onSave={handleSaveProperty}
      onClose={handleModalClose}
      disabled={!!Object.keys(errors).length || !nomicProperty.appName || !nomicProperty.dataSource}
      size={fullScreen ? 'medium' : 'small'}
      labelYes={nomicProperty.id ? t('update') : t('create')}
      loadingYes={loading}
    >
      <div className={Style.contentWrapperNomicModal}>
        <h2>{nomicProperty.id ? t('update') : t('create')}</h2>
        <p className={Style.propertyDescriptionNomicModal}>
          {t('create_desc')}{' '}
          <a href='https://aurecon-atlas.nomic.ai/' target='_blank' rel='noopener noreferrer'>
            Nomic Atlas
          </a>
        </p>
        <Grid row gap={12}>
          <Grid item xs={12}>
            <RecallDropdown
              open='down'
              label={'type'}
              items={[{ id: NomicDataSourceEnum.EXISTING_NOMIC, label: t('exist_map') }]}
              selectedItem={nomicProperty.dataSource}
              onSelectItem={(val) => {
                handleValueChange(NomicPropertyModalFields.dataSource, val)
              }}
              cssClass={Style.type}
              placeholder={t('select_option')}
              chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
            />
            {errors.dataSource?.[0] && <div>{errors.dataSource[0]}</div>}
          </Grid>
          <Grid item xs={12}>
            <RecallFormInput
              required
              placeholder={t('enter_name')}
              value={nomicProperty.appName}
              onChange={(val) => handleValueChange(NomicPropertyModalFields.appName, val, 200)}
              cssClass={Style.description}
              label={t('app_name')}
              error={errors.appName?.[0]}
              chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
            />
            {!errors.appName?.[0] && <LimitedCharactersLeft maxLength={200} value={nomicProperty.appName} />}
          </Grid>
          <Grid item xs={12}>
            <RecallFormInput
              required
              placeholder={t('map_url')}
              value={nomicProperty.nomicMap}
              onChange={(val) => handleValueChange(NomicPropertyModalFields.nomicMap, val)}
              cssClass={Style.description}
              label={t('nomic_map')}
              chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
              error={errors.nomicMap?.[0]}
            />
          </Grid>
          <Grid item xs={12} cssClass={Style.infoRowNomicModal}>
            <Icon type='lightbulb' cssClass={Style.infoIcon} outlined />
            <span>
              <Trans i18nKey='create_desc2' components={[<b key={0} />, <b key={1} />]} />{' '}
            </span>
          </Grid>
          <Grid item xs={12} cssClass={Style.instructions}>
            <NomicImageLeft />
            <Icon type='arrow_circle_right' outlined={true} />
            <NomicImageRight />
          </Grid>
        </Grid>
      </div>
    </RecallModal>
  )
}

export default NomicModal
