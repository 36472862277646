export enum AppSlidePositionEnum {
  NONE = 0,
  MAIN = 1,
  LEFT = 2,
  RIGHT = 3,
}

export const AppSlidePositionLabel = {
  [AppSlidePositionEnum.MAIN]: 'Main',
  [AppSlidePositionEnum.LEFT]: 'Left',
  [AppSlidePositionEnum.RIGHT]: 'Right',
}
