import { HttpContentType, HttpMethod } from '../enums/ApiRequestConstants'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { IResponse, IResponseTypes, WrapIResponse } from '../models/api/IResponse'

const responseFactory = {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  [HttpContentType.JSON]: (response: Response): Promise<any> => response.json(),
  [HttpContentType.TEXT]: async (response: Response): Promise<IResponse<string>> =>
    WrapIResponse(await response.text()),
  [HttpContentType.BLOB]: async (response: Response): Promise<IResponse<Blob>> =>
    WrapIResponse(await response.blob(), undefined, undefined, response.headers),
}

export const headersWithToken = (
  token: string | undefined,
  contentType = HttpContentType.JSON,
  nomicToken?: string,
): HeadersInit => {
  const headers = {
    Authorization: `Bearer ${token}`,
    nomictoken: nomicToken ?? '',
  }

  if (contentType !== HttpContentType.BLOB) headers['Content-Type'] = contentType

  return headers
}

export const getAsync = async <TRequest extends IBodyRequestModel, TData extends IResponseTypes>(
  url: string,
  data: TRequest,
  contentType?: string,
  cancel?: AbortController,
): Promise<IResponse<TData>> => handleRequestAsync(url, data, HttpMethod.GET, contentType, cancel)

export const postAsync = async <TRequest extends IBodyRequestModel, TData extends IResponseTypes>(
  url: string,
  data: TRequest,
  contentType?: string,
  cancel?: AbortController,
): Promise<IResponse<TData>> => handleRequestAsync(url, data, HttpMethod.POST, contentType, cancel)

export const putAsync = async <TRequest extends IBodyRequestModel, TData extends IResponseTypes>(
  url: string,
  data: TRequest,
  contentType?: string,
  cancel?: AbortController,
): Promise<IResponse<TData>> => handleRequestAsync(url, data, HttpMethod.PUT, contentType, cancel)

export const patchAsync = async <TRequest extends IBodyRequestModel, TData extends IResponseTypes>(
  url: string,
  data: TRequest,
  contentType?: string,
  cancel?: AbortController,
): Promise<IResponse<TData>> => handleRequestAsync(url, data, HttpMethod.PATCH, contentType, cancel)

export const deleteAsync = async <TRequest extends IBodyRequestModel, TData extends IResponseTypes>(
  url: string,
  data: TRequest,
  contentType?: string,
  cancel?: AbortController,
): Promise<IResponse<TData>> => handleRequestAsync(url, data, HttpMethod.DELETE, contentType, cancel)

export const postMultiFormDataAsync = async <TData extends IResponseTypes>(
  url: string,
  token: string,
  data: FormData,
  method = HttpMethod.POST,
  cancel?: AbortController,
): Promise<IResponse<TData>> => {
  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${token}` },
    method,
    body: data,
    signal: cancel?.signal,
  })

  const result: IResponse<TData> = response.ok
    ? await response.json()
    : { success: false, message: response.statusText }
  return result
}

const handleRequestAsync = async <TRequest extends IBodyRequestModel, TData extends IResponseTypes>(
  apiUrl: string,
  data: TRequest,
  method: string,
  contentType?: string,
  cancel?: AbortController,
): Promise<IResponse<TData>> => {
  const token = data.token
  data.token = undefined

  const nomicToken = data.nomicToken
  data.nomicToken = undefined

  try {
    const response = await fetch(apiUrl, {
      headers: headersWithToken(token, contentType, nomicToken),
      method: method,
      body: method !== HttpMethod.GET ? JSON.stringify(data) : null,
      signal: cancel?.signal,
    })

    if (response.ok) {
      return await responseFactory[contentType ?? HttpContentType.JSON](response)
    }
    const errorMessage = (await response.json())?.message || response.statusText
    return { success: false, message: errorMessage, status: response.status } as IResponse<TData>
  } catch (error) {
    return { success: false, message: (error as Error)?.message } as IResponse<TData>
  }
}
