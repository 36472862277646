import { MAX_NO_ATTACHMENTS } from '../config/config'
import { ChatTypeToPath } from '../enums/ChatTypeEnum'
import { getAsync, putAsync } from '../helpers/apiRequest'
import { IQuestion } from '../models/IQuestionModels'
import { ICreateQuestionRequestModel } from '../models/api/ICreateQuestionModels'
import { IGetQuestionsRequestModel, IGetQuestionsResponseModel } from '../models/api/IGetQuestionsModel'
import { IResponse } from '../models/api/IResponse'
import { IUpdateQuestionRequestModel, IUpdateQuestionResponseModel } from '../models/api/IUpdateQuestionModels'
import { TokenExpiryWrapper, TokenManager } from './TokenManager'
import { TFunction } from 'i18next'

const handleError = (err: unknown, t: TFunction) => {
  if (!(err instanceof Error)) return { error: 'unknown error', response: null }

  if (err.name == 'AbortError') {
    console.info('Generating stopped!')
    return { error: t('popup_mess5'), response: null }
  }

  console.error(err)
  return { error: err.name, response: null }
}

export const getQuestionStream = async (
  props: ICreateQuestionRequestModel,
  t: TFunction,
): Promise<{ error: string | null; response: Response | null }> => {
  const tokenManager = TokenManager()
  const token = await tokenManager.requestToken()

  const formData = new FormData()
  formData.append('chatId', props.chatId)
  formData.append('focus', JSON.stringify(props.focus))
  formData.append('question', props.question)
  formData.append('type', props.type.toString())
  formData.append('linkId', props.linkId ?? '')

  props.file?.slice(0, MAX_NO_ATTACHMENTS).forEach((file, i) => {
    formData.append(`file${i}`, file || '')
  })

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      nomictoken: props.nomicToken ?? '',
      'Cache-Control': `no-cache`,
    },
    body: formData,
    signal: props.controller.signal,
  }

  const url = `/v1/question/stream/${ChatTypeToPath[props.type]}`

  try {
    const response = await fetch(url, requestOptions)
    if (!response.ok || !response.body) {
      const details = await response.json()
      return { error: details.message, response: null }
    }

    return { error: null, response }
  } catch (err: unknown) {
    return handleError(err, t)
  }
}

export const getQuestionResponse = async (
  props: ICreateQuestionRequestModel,
  t: TFunction,
): Promise<{ error: string | null; response: IQuestion | null }> => {
  const tokenManager = TokenManager()
  const token = await tokenManager.requestToken()

  const formData = new FormData()
  const file = props.file ? props.file[0] : ''

  formData.append('chatId', props.chatId)
  formData.append('focus', JSON.stringify(props.focus))
  formData.append('question', props.question)
  formData.append('type', props.type.toString())
  formData.append('file', file)

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Cache-Control': `no-cache`,
    },
    body: formData,
    signal: props.controller.signal,
  }

  const url = `/v1/question/stream/${ChatTypeToPath[props.type]}`

  try {
    const response = await fetch(url, requestOptions)
    const details = await response.json()

    if (!response.ok || !response.body) {
      return { error: details.message, response: null }
    }

    return { error: null, response: details }
  } catch (err: unknown) {
    return handleError(err, t)
  }
}

export const updateQuestion = TokenExpiryWrapper(
  (request: IUpdateQuestionRequestModel): Promise<IResponse<IUpdateQuestionResponseModel>> =>
    putAsync('/v1/question', request),
  [],
  null,
)

export const getChatQuestions = TokenExpiryWrapper(
  (request: IGetQuestionsRequestModel): Promise<IResponse<IGetQuestionsResponseModel[]>> =>
    getAsync(`/v1/question/${request.chatId}`, request),
  [],
  null,
)
