import { FC, useMemo } from 'react'
import { IQuestion } from '../models/IQuestionModels'
import { MAX_NO_ATTACHMENTS } from '../config/config'
import ChatQuestionAttachmentFiles from './ChatQuestionAttachmentFiles'
import { v4 as uuidv4 } from 'uuid'

import Style from '../styles/ChatQuestionAttachments.module.sass'

interface IChatQuestionAttachmentsProps {
  question: IQuestion
}

const ChatQuestionAttachments: FC<IChatQuestionAttachmentsProps> = (props) => {
  const { question } = props

  const files = useMemo(() => {
    const attachments = []

    for (let i = 0; i < MAX_NO_ATTACHMENTS; i++) {
      const name = i ? question[`fileName${i}`] : question['fileName']
      if (!name) break

      attachments.push({ id: uuidv4(), name })
    }

    return attachments
  }, [question])

  return (
    <div className={Style.wrapper}>
      {files.map((file, index) => {
        return <ChatQuestionAttachmentFiles key={file.id} question={question} name={file.name} fileId={index} />
      })}
    </div>
  )
}

export default ChatQuestionAttachments
