import { FC } from 'react'
import { useRecoilState } from 'recoil'

import { ATTACHMENT_FILETYPES } from '../config/config'
import { QuestionFile } from '../stores/AppStore'

import Style from '../styles/FileAttachment.module.sass'
import ChatQuestionPreviewAttachmentFiles from './FilePreviewAttachmentFiles'

const FileAttachment: FC = () => {
  const [questionFile, setQuestionFile] = useRecoilState(QuestionFile)

  if (!questionFile) return null

  if (!questionFile.some((file) => ATTACHMENT_FILETYPES.includes(file.type))) return null

  const removeFile = (fileName: string) => {
    const newFiles = questionFile.filter((f) => f.name !== fileName)
    setQuestionFile(newFiles)
  }

  return (
    <div className={Style.wrapper}>
      {questionFile.map((file) => {
        if (!ATTACHMENT_FILETYPES.includes(file.type)) return null

        return (
          <div key={file.name} className={Style.fileItem}>
            <ChatQuestionPreviewAttachmentFiles
              name={file.name}
              blob={file}
              fileType={file.type}
              content=''
              action='clear'
              onFileRemoved={(name) => removeFile(name)}
              isDeleted={false}
              isLoading={false}
              isAnswering={false}
              disablePreview={true}
            />
          </div>
        )
      })}
    </div>
  )
}

export default FileAttachment
