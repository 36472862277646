export const FeedbackTypeEnum = {
  NO_FEEDBACK: 0,
  COHERENCE: 1,
  ACCURACY: 2,
  CONSISTENCY: 4,
  REDUNDANCY: 8,
  SENSITIVE: 16,
}

export const FeedbackTypeToLabel = {
  [FeedbackTypeEnum.NO_FEEDBACK]: 'No feedback',
  [FeedbackTypeEnum.COHERENCE]: 'Coherence',
  [FeedbackTypeEnum.ACCURACY]: 'Accuracy',
  [FeedbackTypeEnum.CONSISTENCY]: 'Consistency',
  [FeedbackTypeEnum.REDUNDANCY]: 'Redundancy',
  [FeedbackTypeEnum.SENSITIVE]: 'Sensitive',
}
