export const AppColour = {
  RECALL_CHAT: '#85C446',
  GPT_CHAT: '#F6D510',
  CODE_CHAT: '#B2E3F4',
  WINWISE: '#45C5B6',
  BAMBOO: '#C7B3E9',
  BAMBOO_APP_PAGE: '#E3D2FF',
  CUSTOM_RECALL_APP: '#1692BF',
  BHP_STANDARDS: '#F36A22',
}
