import { FC, useEffect, useMemo, useState } from 'react'
import { Button, Pill, useToast, FileUpload, sizeUnits } from '@aurecon-creative-technologies/styleguide'
import { appInsights } from '../api/AppInsights'
import Page from '../components/Page'

import ChatBamboo from './ChatBamboo'
import ChatSwitcher from '../components/ChatSwitcher'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'
import { ChatType, QuestionFile } from '../stores/AppStore'
import { useSetRecoilState } from 'recoil'
import PreparingChatModal from '../components/modals/PreparingChatModal'
import { useLanguages } from '../hooks/useLanguages'
import { useMediaQuery } from 'react-responsive'
import {
  BAMBOO_FILE_SIZE_LIMIT,
  FILE_VIEWER_HEIGHT_DESKTOP,
  FILE_VIEWER_HEIGHT_FULL_WIDTH,
  FILE_VIEWER_HEIGHT_MOBILE,
  UI_FULL_WIDTH,
  UI_WIDTH_COLLAPSE,
} from '../config/config'
import PDFViewer from '../components/PDFViewer'
import { useFileUploadConfig } from '../config/useFileUploadConfig'

import Style from '../styles/Bamboo.module.sass'
import { getTextFromPdf } from '../helpers/utils'
import nextId from 'react-id-generator'

const ChatBambooHome: FC = () => {
  if (appInsights) appInsights.trackPageView({ name: 'Bamboo Home' })
  const setChatType = useSetRecoilState(ChatType)
  const setQuestionFile = useSetRecoilState(QuestionFile)
  const [loadingRes, setLoadingRes] = useState(false)
  const { addToast } = useToast()
  const [uploadedFile, setUploadedFile] = useState<File>()
  const [openChat, setOpenChat] = useState(false)
  const [content, setContent] = useState('')
  const { t } = useLanguages()
  const [languageKey, setLanguageKey] = useState(0)

  useEffect(() => {
    setLanguageKey((prevKey) => prevKey + 1)
  }, [t])

  useEffect(() => {
    setChatType(ChatTypeEnum.BAMBOO)
    setQuestionFile(null)

    return () => setChatType(null)
  }, [setChatType, setQuestionFile])

  const onNextClicked = async () => {
    if (!uploadedFile) return
    setLoadingRes(true)

    getTextFromPdf(uploadedFile).then((fileContent) => setContent(fileContent))

    addToast({
      type: 'success',
      title: t('popup_toast'),
      message: t('bamboo_session'),
      timeout: 5000,
      timeLabel: t('popup_toast_timelabel'),
    })

    setLoadingRes(false)
    setOpenChat(true)
  }

  const handleFileChange = (files: File[]) => {
    if (files.length) {
      setUploadedFile(files[0])
      return
    }
    setUploadedFile(undefined)
  }

  const isDesktop = useMediaQuery({ minWidth: UI_WIDTH_COLLAPSE })
  const needFullWidth = useMediaQuery({ minWidth: UI_FULL_WIDTH })

  const viewerHeight = useMemo(() => {
    if (needFullWidth) return FILE_VIEWER_HEIGHT_FULL_WIDTH
    if (isDesktop) return FILE_VIEWER_HEIGHT_DESKTOP
    return FILE_VIEWER_HEIGHT_MOBILE
  }, [isDesktop, needFullWidth])

  const renderPDFViewer = (renderFile?: File) => {
    return (
      <PDFViewer
        file={URL.createObjectURL(renderFile as File)}
        showOnlyAsPreview={false}
        height={viewerHeight}
        viewerId={`viewer-${renderFile?.name ?? 'bamboo'}-${nextId()}`}
      />
    )
  }
  const sizeLimit = BAMBOO_FILE_SIZE_LIMIT
  const fileUnits = sizeUnits.MegaByte
  const fileUploadProps = useFileUploadConfig({
    sizeLimit,
    fileUnits: fileUnits.label as keyof typeof sizeUnits,
    fileTypes: ['.pdf'],
    file: uploadedFile,
    onChange: handleFileChange,
    renderFile: renderPDFViewer,
    Style,
  })

  if (openChat) return <ChatBamboo file={uploadedFile} content={content} />

  return (
    <Page menu contentWrapper>
      <h1 className={Style.headerTitle}>Bamboo</h1>
      <div className={Style.experimental}>
        <Pill colour={13} icon='science' size='small'>
          {t('experimental_tag')}
        </Pill>
      </div>

      <ChatSwitcher activeChat={ChatTypeEnum.BAMBOO} />

      <div className={Style.headerText}>
        <p>{t('bamboo_desc')}</p>
      </div>
      <div className={Style.uploadArea}>
        <h3>{t('upload_header')}</h3>
        <p className={Style.uploadSubtitle}>{t('upload_desc')}</p>
      </div>
      <FileUpload
        key={languageKey}
        {...fileUploadProps}
        sizeLimit={[sizeLimit, fileUnits]}
        height={`${fileUploadProps?.height}px`}
      />

      <div className={Style.bambooFooter}>
        <Button
          label={t('next')}
          type='primary'
          cssClass={Style.nextButton}
          disabled={!uploadedFile || loadingRes}
          onClick={onNextClicked}
        />
      </div>
      <PreparingChatModal />
    </Page>
  )
}

export default ChatBambooHome
