import { getAsync } from '../helpers/apiRequest'
import { IResponse } from '../models/api/IResponse'
import { IUserRequestModel } from '../models/api/IUserRequestModel'
import { IUserModel } from '../models/IUserModel'
import { TokenExpiryWrapper } from './TokenManager'

export const searchUsers = TokenExpiryWrapper(
  (request: IUserRequestModel): Promise<IResponse<IUserModel[]>> => {
    const query = request.external ? '?external=true' : ''
    return getAsync(`/v1/users/search/${request.email}${query}`, request)
  },
  [],
  null,
)
