import { FC } from 'react'
import Style from '../../styles/common/LimitedCharacters.module.sass'
import classNames from 'classnames'
import { useLanguages } from '../../hooks/useLanguages'

export interface ILimitedCharactersLeftProps {
  value?: string
  maxLength: number
  className?: string
}

const LimitedCharactersLeft: FC<ILimitedCharactersLeftProps> = ({ value, maxLength, className }) => {
  const { t } = useLanguages()

  return (
    <div className={classNames(Style.charactersLeft, className)}>
      {maxLength - (value ? value.length : 0)} {t('entity_character')}
    </div>
  )
}

export default LimitedCharactersLeft
