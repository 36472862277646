import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import { COOKIE_LANGUAGE, COOKIE_LOGIN_LANGUAGES } from '../config/config'
import { ILangJson } from '../hooks/useLanguages'

const fallbackLanguagefile = require('../assets/i18n/lang_en.jsonc')

const res = await fetch(fallbackLanguagefile)
const restext = await res.text()

export const fallbackLanguage = JSON.parse(restext.replace(/\/\/.*/g, ''))

// setup the internationalization object
i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {},
    lng: 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })
if (fallbackLanguage) i18next.addResourceBundle('en', 'translation', fallbackLanguage)

const cookieLangs = localStorage.getItem(COOKIE_LOGIN_LANGUAGES)
if (cookieLangs) {
  const loginLanguages = JSON.parse(cookieLangs) as { langs: ILangJson[]; date: number }
  if (loginLanguages.langs.length) {
    loginLanguages.langs.forEach((l) =>
      i18next.addResourceBundle(l.lang, 'translation', { ...fallbackLanguage, ...l.def }),
    )
    const cookieLang = localStorage.getItem(COOKIE_LANGUAGE)
    const browserLang = loginLanguages.langs.find((l) => navigator.language.startsWith(String(l.lang)))
    const value = String(cookieLang ?? browserLang?.lang ?? 'en')
    i18next.changeLanguage(value || 'en')
  }
}
