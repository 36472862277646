import Joi from 'joi'
import { APP_SLIDE_DESCRIPTION_MAX_LENGTH, APP_MINI_CARD_DESCRIPTION_MAX_LENGTH } from '../config/config'
import { AppSlidePositionEnum } from '../enums/AppSlidePosition'

export const AppSlideFields = {
  position: 'position',
  language: 'language',
  sortOrder: 'sortOrder',
  subTitle: 'subTitle',
  title: 'title',
  description: 'description',
  descriptionText: 'descriptionText',
  learnMore: 'learnMore',
  url: 'url',
}

export const appSlideSchema = () => {
  return Joi.object({
    [AppSlideFields.position]: Joi.number()
      .valid(AppSlidePositionEnum.MAIN, AppSlidePositionEnum.LEFT, AppSlidePositionEnum.RIGHT)
      .required()
      .messages({
        'number.empty': 'Position is required',
        'number.only': 'Invalid position value',
      }),
    [AppSlideFields.sortOrder]: Joi.number().integer().min(0).required().messages({
      'number.empty': 'Sort Order is required',
      'number.min': 'Sort Order min is required',
    }),
    [AppSlideFields.language]: Joi.string().trim().min(2).max(2).required().messages({
      'string.empty': 'Language is required',
      'string.min': 'Language min is required',
      'string.max': 'Language max is required',
    }),
    [AppSlideFields.title]: Joi.string().trim().min(1).max(20).required().messages({
      'string.empty': 'Cannot be empty',
      'string.max': 'Title must be less than 20 characters',
    }),
    [AppSlideFields.subTitle]: Joi.string().trim().min(1).max(50).required().messages({
      'string.empty': 'Cannot be empty',
      'string.max': 'Mini Title must be less than 50 characters',
    }),
    [AppSlideFields.descriptionText]: Joi.string()
      .trim()
      .min(1)
      .when(AppSlideFields.position, {
        is: Joi.valid(AppSlidePositionEnum.LEFT, AppSlidePositionEnum.RIGHT),
        then: Joi.string()
          .max(APP_MINI_CARD_DESCRIPTION_MAX_LENGTH)
          .messages({
            'string.max': `Description must be less than ${APP_MINI_CARD_DESCRIPTION_MAX_LENGTH} characters`,
          }),
        otherwise: Joi.string()
          .max(APP_SLIDE_DESCRIPTION_MAX_LENGTH)
          .messages({
            'string.max': `Description must be less than ${APP_SLIDE_DESCRIPTION_MAX_LENGTH} characters`,
          }),
      })
      .required()
      .messages({
        'string.empty': 'Cannot be empty',
      }),
    [AppSlideFields.description]: Joi.string()
      .trim()
      .required()
      .messages({
        'string.empty': 'Cannot be empty',
        'string.max': `Description must be less than ${APP_SLIDE_DESCRIPTION_MAX_LENGTH} characters`,
      }),
    [AppSlideFields.learnMore]: Joi.boolean().required().messages({
      'boolean.empty': 'Learn More is required',
    }),
    [AppSlideFields.url]: Joi.when(AppSlideFields.position, {
      is: Joi.valid(AppSlidePositionEnum.LEFT, AppSlidePositionEnum.RIGHT),
      then: Joi.string().trim().uri().required().messages({
        'string.empty': 'Cannot be empty',
        'string.uri': 'Invalid URL',
      }),
      otherwise: Joi.when(AppSlideFields.learnMore, {
        is: true,
        then: Joi.string().trim().uri().required().messages({
          'string.empty': 'URL is required when Learn More is enabled',
          'string.uri': 'Invalid URL',
        }),
      }),
    }),
  }).unknown(true)
}

export const appSlideLengthOnlySchema = () => {
  return Joi.object({
    [AppSlideFields.title]: Joi.string().trim().min(0).max(20).messages({
      'string.empty': 'Cannot be empty',
      'string.max': 'Title must be less than 20 characters',
    }),
    [AppSlideFields.subTitle]: Joi.string().trim().min(0).max(50).messages({
      'string.empty': 'Cannot be empty',
      'string.max': 'Mini Title must be less than 50 characters',
    }),
    [AppSlideFields.descriptionText]: Joi.string()
      .trim()
      .min(0)
      .when(AppSlideFields.position, {
        is: Joi.valid(AppSlidePositionEnum.LEFT, AppSlidePositionEnum.RIGHT),
        then: Joi.string()
          .max(APP_MINI_CARD_DESCRIPTION_MAX_LENGTH)
          .messages({
            'string.max': `Description must be less than ${APP_MINI_CARD_DESCRIPTION_MAX_LENGTH} characters`,
          }),
        otherwise: Joi.string()
          .max(APP_SLIDE_DESCRIPTION_MAX_LENGTH)
          .messages({
            'string.max': `Description must be less than ${APP_SLIDE_DESCRIPTION_MAX_LENGTH} characters`,
          }),
      })
      .messages({
        'string.empty': 'Cannot be empty',
      }),
    [AppSlideFields.learnMore]: Joi.boolean().required().messages({
      'boolean.empty': 'Learn More is required',
    }),
    [AppSlideFields.url]: Joi.when(AppSlideFields.position, {
      is: Joi.valid(AppSlidePositionEnum.LEFT, AppSlidePositionEnum.RIGHT),
      then: Joi.string().trim().allow('').uri().messages({
        'string.empty': 'Cannot be empty',
        'string.uri': 'Invalid URL',
      }),
      otherwise: Joi.when(AppSlideFields.learnMore, {
        is: true,
        then: Joi.string().trim().uri().required().messages({
          'string.empty': 'URL is required when Learn More is enabled',
          'string.uri': 'Invalid URL',
        }),
      }),
    }),
  }).unknown(true)
}
