import { FC, useRef } from 'react'
import { Button } from '@aurecon-creative-technologies/styleguide'
import { appInsights } from '../api/AppInsights'
import Page from '../components/Page'
import { Trans } from 'react-i18next'
import { useLanguages } from '../hooks/useLanguages'
import LoadingScreen from '../components/LoadingScreen'

import Style from '../styles/StaticPage.module.sass'

const TermsAndConditions: FC = () => {
  const contentContainerRef = useRef<HTMLDivElement>(null)
  const { t, i18n } = useLanguages()

  if (appInsights) appInsights.trackPageView({ name: 'Terms And Conditions' })

  const handleBackToTop = () => {
    if (!contentContainerRef.current) return
    contentContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  if (!i18n)
    return (
      <Page>
        <LoadingScreen text={t('loading_translation')} />
      </Page>
    )

  return (
    <Page menu contentWrapper>
      <div className={Style.staticContent} ref={contentContainerRef}>
        <h1>
          {t('term_condition')}
          <sup>TM</sup>
        </h1>
        <ol className={Style.list}>
          <li>
            <span>{t('overview_title')}</span>
            <p>
              {t('overview_desc')}
              <br />
              <br />
              {t('overview_desc2')}
            </p>
            <ul className={Style.listSubLevel1}>
              <li>
                <span>{t('access_title')}</span>
                <ul>
                  <li>{t('access_desc')}</li>
                  <li>{t('access_desc2')}</li>
                </ul>
              </li>
              <li>
                <span>{t('responsible_title')}</span>
                <ul className={Style.listSubLevel2}>
                  <li>
                    <span>{t('responsible_desc')}</span>
                    <ul>
                      <li>{t('responsible_desc2')}</li>
                      <li>
                        <span>{t('responsible_desc3')}</span>
                        <ul>
                          <li>{t('responsible_desc4')}</li>
                          <li>{t('responsible_desc5')}</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span>{t('responsible_desc6')}</span>
                    <ul>
                      <li>
                        <span>{t('responsible_desc7')}</span>
                        <ul>
                          <li>{t('responsible_desc8')}</li>
                          <li>{t('responsible_desc9')}</li>
                        </ul>
                      </li>
                      <li>{t('responsible_desc10')}</li>
                      <li>{t('responsible_desc11')}</li>
                      <li>{t('responsible_desc12')}</li>
                    </ul>
                  </li>
                  <li>
                    <span>{t('responsible_desc13')}</span>
                    <br />
                    {t('responsible_desc14')}
                  </li>
                </ul>
              </li>
              <li>
                <span>{t('responsible_desc15')}</span>
                <ul>
                  <li>{t('responsible_desc16')}</li>
                  <li>{t('responsible_desc17')}</li>
                  <li>{t('responsible_desc18')}</li>
                  <li>{t('responsible_desc19')}</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <span>{t('contact_title')}</span>
            <p>{t('contact_desc')}</p>
            <p>
              <Trans
                i18nKey='contact_desc2'
                t={t}
                components={[
                  <a
                    key={0}
                    href='mailto:melbourne@aurecongroup.com'
                    target='_blank'
                    rel='noreferrer'
                    aria-label='Email Melbourne Office'
                  ></a>,
                ]}
              ></Trans>
            </p>
          </li>
          <li>
            <span>{t('term_title')}</span>
            <p>{t('term_desc')}</p>
          </li>
          <li>
            <span>{t('privacy_title')}</span>
            <p>
              <Trans
                i18nKey='privacy_desc'
                t={t}
                components={[
                  <a
                    key={0}
                    href='https://www.aurecongroup.com/footer/privacy-policy'
                    target='_blank'
                    rel='noreferrer'
                    aria-label='Aurecon Privacy Policy'
                  ></a>,
                ]}
              ></Trans>
            </p>
            <p>{t('privacy_desc2')}</p>
            <p>{t('privacy_desc3')}</p>
            <p>
              <Trans
                i18nKey='privacy_desc4'
                t={t}
                components={[
                  <a
                    key={0}
                    href='mailto:melbourne@aurecongroup.com'
                    target='_blank'
                    rel='noreferrer'
                    aria-label='Email Melbourne Office'
                  ></a>,
                ]}
              ></Trans>
            </p>
            <p>{t('privacy_desc5')}</p>
            <ul>
              <li>{t('privacy_desc6')}</li>
              <li>
                <Trans
                  i18nKey='privacy_desc7'
                  t={t}
                  components={[
                    <a
                      key={0}
                      href='https://www.smartlook.com'
                      target='_blank'
                      rel='noreferrer'
                      aria-label='Smartlook page link'
                    ></a>,
                  ]}
                ></Trans>
              </li>
              <li>{t('privacy_desc8')}</li>
            </ul>
          </li>
          <li>
            <span>{t('cookie_title')}</span>
            <p>{t('cookie_desc')}</p>
            <p>{t('cookie_desc2')}</p>
          </li>
        </ol>
        <div className={Style.backToTopBtn}>
          <Button type='secondary' label={t('back_top')} onClick={handleBackToTop} />
        </div>
      </div>
    </Page>
  )
}

export default TermsAndConditions
