import Joi from 'joi'
import { validateSpecialCharacters } from '../helpers/utils'

export const NomicPropertyModalFields = {
  dataSource: 'dataSource',
  appName: 'appName',
  nomicMap: 'nomicMap',
}

export const nomicPropertyModalSchema = () => {
  return Joi.object({
    [NomicPropertyModalFields.dataSource]: Joi.number()
      .integer()
      .min(1)
      .max(1)
      .required()
      .messages({ 'number.empty': 'Datasource selection is required' }),
    [NomicPropertyModalFields.appName]: Joi.string()
      .trim()
      .min(1)
      .max(200)
      .required()
      .custom((text, helper) => {
        const validForSpecialChars = validateSpecialCharacters(text)
        if (!validForSpecialChars) {
          return helper.message({ custom: 'Cannot contain special character in App name' })
        }
        return true
      })
      .messages({ 'string.empty': 'App Name is required' }),
    [NomicPropertyModalFields.nomicMap]: Joi.string()
      .trim()
      .uri()
      .required()
      .messages({ 'string.empty': 'Nomic Map URL is required', 'string.uri': 'Nomic Map must be a valid URL' }),
  })
}
