import { FC } from 'react'

import { ReactComponent as RecallMenuIcon } from '../../src/assets/Recall_menu_icon.svg'
import { ReactComponent as GPTMenuIcon } from '../../src/assets/ChatGPT_menu_icon.svg'
import { ReactComponent as WinWiseLogo } from '../../src/assets/WinWise_logo.svg'
import { ReactComponent as BambooLogo } from '../../src/assets/Bamboo_logo.svg'
import { ReactComponent as CRALogo } from '../../src/assets/customRecall_icon.svg'
import { ReactComponent as BHPLogo } from '../../src/assets/BHP_logo.svg'

import { appInsights } from '../api/AppInsights'
import Page from '../components/Page'
import AppCard from '../components/AppCard'
import { AppColour } from '../enums/AppColourConstants'
import { useShowFeature } from '../hooks/useShowFeature'
import { FeatureFlagEnum } from '../enums/FeatureFlagEnum'
import { useChangeChat } from '../hooks/useChangeChat'
import { useLanguages } from '../hooks/useLanguages'

import Style from '../styles/Apps.module.sass'

const textColourBlack = '#000000'
const textColourWhite = '#ffffff'

const Home: FC = () => {
  const { openRecallChat, openChatGPT, openWinWise, openBamboo, openCustomRecallApp, openBHPStandards } =
    useChangeChat()
  const { t } = useLanguages()

  if (appInsights) appInsights.trackPageView({ name: 'Apps' })
  const showBambooChat = useShowFeature(FeatureFlagEnum.ShowBambooChat)
  const showCustomRecallChat = useShowFeature(FeatureFlagEnum.ShowCustomRecallApp)
  const showBHPStandardsApp = useShowFeature(FeatureFlagEnum.ShowBHPStandardsApp)

  return (
    <Page menu contentWrapper contentWrapperWide>
      <h1>{t('app_header')}</h1>

      <div className={Style.cards}>
        <AppCard
          appName='Recall Chat'
          AppIcon={RecallMenuIcon}
          appContents={
            <>
              <p>{t('recall_content')}</p>
              <p>{t('recall_content2')}</p>
            </>
          }
          appButtonLabel={t('recall_content3')}
          buttonColour={AppColour.RECALL_CHAT}
          onClick={openRecallChat}
          textColor={textColourBlack}
        />

        <AppCard
          appName='Secure ChatGPT'
          AppIcon={GPTMenuIcon}
          appContents={
            <>
              <p>{t('securegpt_content')}</p>
              <p>{t('securegpt_content2')}</p>
            </>
          }
          appButtonLabel={t('securegpt_content3')}
          buttonColour={AppColour.GPT_CHAT}
          onClick={openChatGPT}
          textColor={textColourBlack}
        />

        <AppCard
          appName='WinWise'
          AppIcon={WinWiseLogo}
          appContents={
            <>
              <p>{t('winwise_content')}</p>
              <p>{t('winwise_content2')}</p>
            </>
          }
          appButtonLabel={t('winwise_content3')}
          buttonColour={AppColour.WINWISE}
          onClick={openWinWise}
          textColor={textColourBlack}
        />
        {showBambooChat.enabled && (
          <AppCard
            appName='Bamboo'
            AppIcon={BambooLogo}
            appContents={
              <>
                <p>{t('bamboo_content')}</p>
                <p>{t('bamboo_content2')}</p>
              </>
            }
            appButtonLabel={t('bamboo_content3')}
            buttonColour={AppColour.BAMBOO_APP_PAGE}
            onClick={openBamboo}
            experimental
            textColor={textColourBlack}
          />
        )}

        {showCustomRecallChat.enabled && (
          <AppCard
            appName='Custom Recall App'
            AppIcon={CRALogo}
            appContents={
              <>
                <p>{t('custom_app_content')}</p>
                <p>{t('custom_app_content2')}</p>
              </>
            }
            appButtonLabel='Custom Recall App'
            buttonColour={AppColour.CUSTOM_RECALL_APP}
            onClick={openCustomRecallApp}
            textColor={textColourWhite}
          />
        )}
        {showBHPStandardsApp.enabled && (
          <AppCard
            appName='Standards App'
            AppIcon={BHPLogo}
            appContents={
              <>
                <p>{t('bhp_content')}</p>
                <p>{t('bhp_content2')}</p>
              </>
            }
            appButtonLabel='Query BHP Standards'
            buttonColour={AppColour.BHP_STANDARDS}
            onClick={openBHPStandards}
            textColor={textColourWhite}
          />
        )}
      </div>
    </Page>
  )
}

export default Home
