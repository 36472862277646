import Joi from 'joi'
import { SYSTEMS_BANNER_MAX_LENGTH } from '../config/config'

export const SystemBannerFields = {
  message: 'message',
}

export const systemBannerSchema = (extProps: { bannerVisible: boolean }) => {
  const { bannerVisible } = extProps
  return Joi.object({
    [SystemBannerFields.message]: Joi.string()
      .trim()
      .min(0)
      .max(SYSTEMS_BANNER_MAX_LENGTH)
      .custom((text, helper) => {
        if (bannerVisible && !text) return helper.message({ custom: 'Description is cannot be empty.' })
        return true
      })
      .messages({
        'string.max': 'Description must be less than 500 characters',
      }),
  }).unknown(true)
}
