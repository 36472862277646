import { FC } from 'react'
import Style from '../styles/AppPill.module.sass'

interface IAppPillProps {
  colour: string
  content: string
  onClick: () => void
}

export const AppPill: FC<IAppPillProps> = (props) => {
  const { content, colour, onClick } = props

  return (
    <div className={Style.pill} style={{ borderColor: colour }} onClick={onClick} role='none'>
      {content}
    </div>
  )
}
