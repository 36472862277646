import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Toggle, useToast } from '@aurecon-creative-technologies/styleguide'
import Style from '../styles/SystemBannerEditor.module.sass'
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { Language, SystemBannersLoader } from '../stores/AppStore'
import { addSystemBannerInfo, ISystemBannerInfo } from '../api/AppService'
import LanguageSwitch from './LanguageSwitch'
import { ResponseData } from '../models/api/IResponse'
import { JODIT_CONFIG, SYSTEMS_BANNER_MAX_LENGTH } from '../config/config'
import { systemBannerSchema } from '../validators/systemBannerValidator'
import { getErrorsFromValidationResult, IErrorModel } from '../validators/commonValidator'
import RecallJoditEditor from './common/RecallJoditEditor'
import { LanguageEnum } from '../enums/LanguageEnum'
import { getTextFromHTML } from '../helpers/utils'

interface ILangMessage {
  en: string
  th: string
  vi: string
}

const SystemBannerEditor: FC = () => {
  const systemBannersLoader = useRecoilValueLoadable(SystemBannersLoader)
  const refreshSystemBanner = useRecoilRefresher_UNSTABLE(SystemBannersLoader)
  const [errors, setErrors] = useState<IErrorModel>({})
  const appLanguage = useRecoilValue(Language)
  const [currentLanguage, setCurrentLanguage] = useState(appLanguage)
  const [systemBanners, setSystemBanners] = useState<ISystemBannerInfo[]>([])
  const [messages, setMessages] = useState<ILangMessage>({ en: '', th: '', vi: '' })
  const [loading, setLoading] = useState(false)

  const { addToast } = useToast()

  useEffect(() => {
    if (systemBannersLoader.state !== 'hasValue' || !systemBannersLoader.contents) return
    setSystemBanners(systemBannersLoader.contents)

    const msgs = { en: '', th: '', vi: '' }
    systemBannersLoader.contents.forEach((c) => (msgs[c.language] = c.message))
    setMessages(msgs)
  }, [appLanguage, systemBannersLoader.contents, systemBannersLoader.state])

  const resetSystemBanner = () => {
    setSystemBanners(systemBannersLoader.contents)
  }

  const onLangChange = async (language: string) => {
    setCurrentLanguage(language)
    setErrors({})
  }

  const onSystemBannerSave = async () => {
    if (!systemBanners.length) return
    setLoading(true)
    const res = ResponseData(await addSystemBannerInfo({ banners: systemBanners }))
    setLoading(false)

    refreshSystemBanner()

    if (!res) {
      addToast({
        type: 'error',
        message: 'Error updating system banner',
        timeout: 5000,
      })
      return
    }

    addToast({
      type: 'success',
      message: 'System banner successfully update',
      timeout: 5000,
    })
  }

  const onChange = (key: string, value: string | boolean) => {
    const banners = [...systemBanners]
    const updateSystemBanner = banners.find((s) => s.language === currentLanguage)
    if (!updateSystemBanner) return

    setSystemBanners([
      { ...updateSystemBanner, [key]: value },
      ...banners.filter((s) => s.language !== currentLanguage),
    ])
  }

  const systemBanner = useMemo(() => {
    return systemBanners.find((s) => s.language === currentLanguage)
  }, [currentLanguage, systemBanners])

  const validateBanner = useCallback(
    (banner: ISystemBannerInfo) => {
      const validationResult = systemBannerSchema({ bannerVisible: banner.enabled ?? false }).validate(
        { message: messages[banner.language] },
        { abortEarly: false },
      )
      const errs = getErrorsFromValidationResult(validationResult)
      return errs
    },
    [messages],
  )

  useEffect(() => {
    if (!systemBanner) return
    const hasEnglishBanner =
      systemBanners.find((c) => c.language === LanguageEnum.ENGLISH && getTextFromHTML(c.message)) &&
      systemBanner?.language !== LanguageEnum.ENGLISH

    if (hasEnglishBanner) return

    const errs = validateBanner(systemBanner)
    setErrors(errs)
  }, [systemBanner, systemBanners, validateBanner])

  const canSave = useMemo(() => {
    const isEngBannerThere = systemBanners.find(
      (c) => c.language === LanguageEnum.ENGLISH && getTextFromHTML(c.message),
    )
    if (isEngBannerThere) return true
    const allErrors = systemBanners.map((s) => validateBanner(s))
    const noErrorsLeft = allErrors.every((err) => Object.keys(err).length === 0)

    return noErrorsLeft
  }, [systemBanners, validateBanner])

  return (
    <div>
      <h1 className={Style.title}>System Banner</h1>
      <div className={Style.systemBanner}>
        <RecallJoditEditor
          config={JODIT_CONFIG}
          value={systemBanner?.message ?? ''}
          onBlur={(message) => onChange('message', message)}
          onTextChange={(message) => setMessages((m) => ({ ...m, [systemBanner?.language ?? appLanguage]: message }))}
          maxLength={SYSTEMS_BANNER_MAX_LENGTH}
          label='Description'
          error={errors?.message?.[0]}
          required={true}
        />
        <div className={Style.toggleArea}>
          <Toggle
            label='Visibility'
            value={systemBanner?.enabled}
            cssClass={Style.toggle}
            onChange={(enabled) => onChange('enabled', enabled)}
          />
          <LanguageSwitch value={systemBanner?.language ?? appLanguage} onChange={onLangChange} isRightAligned={true} />
        </div>
      </div>

      <div className={Style.footer}>
        <div>
          <Button
            label='Cancel'
            size='medium'
            type='secondary'
            default
            cssClass={Style.cancelEditBanner}
            onClick={resetSystemBanner}
          />
          <Button
            label='Save'
            size='medium'
            type='primary'
            loading={loading}
            disabled={!canSave}
            onClick={onSystemBannerSave}
          />
        </div>
      </div>
    </div>
  )
}

export default SystemBannerEditor
