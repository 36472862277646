import { FC, ReactNode } from 'react'
import { Grid } from '@aurecon-creative-technologies/styleguide'

import Style from '../../styles/common/ConfirmModal.module.sass'
import RecallModal from '../common/RecallModal'

interface ConfirmModalProps {
  title: string
  message: string | ReactNode
  open: boolean
  labelYes?: string
  labelClose?: string
  onClose: () => void
  onSave: () => void
  chatType: number
  size?: 'small' | 'medium' | 'large'
  loadingYes?: boolean
  disabled?: boolean
}

const ConfirmModal: FC<ConfirmModalProps> = (props) => {
  const { title, message, open, labelYes, labelClose, onClose, onSave, size, loadingYes, disabled, chatType } = props

  return (
    <RecallModal
      isShowing={open}
      onClose={onClose}
      chatType={chatType}
      disabled={disabled || false}
      onSave={onSave}
      size={size ?? 'small'}
      loadingYes={loadingYes}
      labelYes={labelYes}
      labelNo={labelClose}
      hideCloseIcon={true}
    >
      <Grid row cssClass={Style.confirmModalContainer}>
        <Grid item xs={12} cell>
          <div className={Style.titleHolder}>
            <h2>{title}</h2>
          </div>
          <div className={Style.messageHolder}>{message}</div>
        </Grid>
      </Grid>
    </RecallModal>
  )
}

export default ConfirmModal
