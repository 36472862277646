import { FC } from 'react'
import { Button } from '@aurecon-creative-technologies/styleguide'

import { IMAGE_EXT, OFFICE_EXT, PDF_EXT, TXT_EXT } from '../../config/config'
import PdfPreview from '../../assets/pdf_placeholder.png'
import FilePreview from '../../assets/file_placeholder.png'

import Style from '../../styles/FilePreviewModal.module.sass'
import { fileExtension } from '../../helpers/fileUtils'

interface IFilePreviewModalProps {
  show: boolean
  content: string
  url: string | null
  name: string
  onClose: () => void
}

const FilePreviewModal: FC<IFilePreviewModalProps> = (props) => {
  const { show, content, url, name, onClose } = props

  const fileExt = fileExtension(name)

  const imageType = IMAGE_EXT.some((ext) => ext === fileExt)
  const textType = TXT_EXT.some((ext) => ext === fileExt)
  const pdfType = PDF_EXT.some((ext) => ext === fileExt)
  const officeType = OFFICE_EXT.some((ext) => ext === fileExt)

  if (!show) return null

  return (
    <div className={Style.wrapper}>
      <Button cssClass={Style.closeButton} type='icon-round' icon='close' onClick={onClose} />
      <div className={Style.content}>
        {imageType && url && <img className={Style.image} src={url} alt='File preview' />}
        {textType && <pre>{content}</pre>}
        {pdfType && <img className={Style.image} src={PdfPreview} alt='PDF file preview' />}
        {officeType && <img className={Style.image} src={FilePreview} alt='File preview' />}
        <div className={Style.name}>{name}</div>
      </div>
    </div>
  )
}

export default FilePreviewModal
