import { FC } from 'react'
import { IButtonProps, Modal } from '@aurecon-creative-technologies/styleguide'
import { useRecoilState } from 'recoil'

import ChatQuestion from '../ChatQuestion'

import { CurrentQuestion, ShowSeeQuestionModal } from '../../stores/AppStore'
import ChatAnswer from '../ChatAnswer'

const SeeQuestionModal: FC = () => {
  const [currentQuestion, setCurrentQuestion] = useRecoilState(CurrentQuestion)
  const [showSeeQuestionModal, setShowSeeQuestionModal] = useRecoilState(ShowSeeQuestionModal)

  const handleModalClose = () => {
    setCurrentQuestion(null)
    setShowSeeQuestionModal(false)
  }

  const modalActions = [
    {
      label: 'Done',
      onClick: handleModalClose,
    },
  ] as IButtonProps[]

  return (
    <Modal isShowing={showSeeQuestionModal} onClose={handleModalClose} actions={modalActions} size='large'>
      {currentQuestion && (
        <>
          <ChatQuestion question={currentQuestion} />
          <ChatAnswer question={currentQuestion} />
        </>
      )}
    </Modal>
  )
}

export default SeeQuestionModal
