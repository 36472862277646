export const formatText = (text: string) => {
  const normalisedText = text.replace('\r', '\n')
  const textArray = normalisedText.split('\n')

  return textArray.map((text) => {
    return (
      <span key={text}>
        {text}
        <br />
      </span>
    )
  })
}
