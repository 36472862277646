import { ChatTypeEnum, ChatTypeToLabel } from '../enums/ChatTypeEnum'

export const chatPillDetails = (chatType: number, isDesktop = true, customAppName = '') => {
  let pillLabel = isDesktop ? ChatTypeToLabel[chatType].desktop : ChatTypeToLabel[chatType].mobile
  const pillColour = ChatTypeToLabel[chatType].colour

  if (chatType === ChatTypeEnum.CUSTOM_RECALL_APP && customAppName) {
    pillLabel = customAppName
  }

  return { pillLabel, pillColour }
}
