import { FC, useRef, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { Loader } from '@aurecon-creative-technologies/styleguide'

import { appInsights } from '../api/AppInsights'
import { getChatQuestions } from '../api/QuestionService'
import { ChatSession, ChatType, NotFound, ScrollChat } from '../stores/AppStore'
import Page from '../components/Page'
import ChatQuestion from '../components/ChatQuestion'
import ChatAnswer from '../components/ChatAnswer'
import ChatNotFound from '../components/ChatNotFound'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'
import FileDownloadModal from '../components/modals/FileDownloadModal'
import { useShowFeature } from '../hooks/useShowFeature'
import { FeatureFlagEnum } from '../enums/FeatureFlagEnum'
import ChatSwitcher from '../components/ChatSwitcher'

import Style from '../styles/Home.module.sass'
import AnswerEditModal from '../components/modals/AnswerEditModal'

const ChatBHPStandards: FC = () => {
  const [chatSession, setChatSession] = useRecoilState(ChatSession)
  const [notFound, setNotFound] = useRecoilState(NotFound)
  const [scrollChat, setScrollChat] = useRecoilState(ScrollChat)
  const setChatType = useSetRecoilState(ChatType)
  const showBHPStandards = useShowFeature(FeatureFlagEnum.ShowBHPStandardsApp)
  const contentsRef = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState(false)
  const { chatId } = useParams()

  useEffect(() => {
    setChatType(ChatTypeEnum.BHP_STANDARDS)

    return () => setChatType(null)
  }, [setChatType])

  useEffect(() => {
    if (showBHPStandards.enabled) return

    setChatType(null)
    location.hash = '#/'
  }, [showBHPStandards, setChatType])

  useEffect(() => {
    const getSessionAsync = async () => {
      const id = chatId || ''
      const questions = await getChatQuestions({ chatId: id })

      if (!questions?.data) {
        setLoading(false)
        setNotFound(true)
        setChatSession(null)
        return
      }

      setChatSession({
        chatId: id,
        questions: questions.data,
        type: ChatTypeEnum.BHP_STANDARDS,
      })

      setLoading(false)
      setNotFound(false)

      setTimeout(() => {
        setScrollChat((s) => s + 1)
      }, 1000)
    }

    if (chatSession?.chatId === chatId) return

    setLoading(true)
    getSessionAsync()
  }, [chatSession, chatId, setChatSession, setNotFound, setScrollChat])

  useEffect(() => {
    if (!contentsRef.current) return

    contentsRef.current.scrollTo({ top: contentsRef.current.scrollHeight, behavior: 'smooth' })
  }, [scrollChat])

  if (appInsights) appInsights.trackPageView({ name: 'BHPStandards' })

  return (
    <Page menu contentWrapper contentsRef={contentsRef}>
      <h1 className={Style.chatTitle}>BHP Standards</h1>
      <ChatSwitcher activeChat={ChatTypeEnum.BHP_STANDARDS} />
      {loading && <Loader label='Loading chat...' />}
      {!loading && notFound && <ChatNotFound />}
      {!loading &&
        chatSession?.questions.map((question) => {
          return (
            <div key={question.rowKey} id={`qa-holder-${question.rowKey}`}>
              <ChatQuestion question={question} />
              <ChatAnswer question={question} />
            </div>
          )
        })}
      <FileDownloadModal />
      <AnswerEditModal />
    </Page>
  )
}

export default ChatBHPStandards
