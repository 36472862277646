import { FC } from 'react'
import { Button, Tooltip, useToast } from '@aurecon-creative-technologies/styleguide'
import { useRecoilState, useSetRecoilState } from 'recoil'

import { IQuestion } from '../models/IQuestionModels'
import { RatingEnum } from '../enums/RatingEnum'
import { ChatSession, CurrentQuestion, ShowEditModal, ShowFeedbackModal } from '../stores/AppStore'
import { ChatTypeActions } from '../enums/ChatTypeEnum'
import { updateQuestion } from '../api/QuestionService'
import { dateToString } from '../helpers/utils'
import { appInsights } from '../api/AppInsights'
import { useLanguages } from '../hooks/useLanguages'

import Style from '../styles/AnswerActions.module.sass'

interface IAnswerActionsProps {
  question: IQuestion
  selectedAnswer: number
}

const AnswerActions: FC<IAnswerActionsProps> = (props) => {
  const [chatSession, setChatSession] = useRecoilState(ChatSession)
  const setCurrentQuestion = useSetRecoilState(CurrentQuestion)
  const setShowEditModal = useSetRecoilState(ShowEditModal)
  const setShowFeedbackModal = useSetRecoilState(ShowFeedbackModal)
  const { addToast } = useToast()
  const { t } = useLanguages()

  const { question, selectedAnswer } = props

  const thumbUp = question.rating === RatingEnum.POSITIVE ? 'green' : 'gray'
  const thumbUpIcon = question.rating === RatingEnum.POSITIVE ? 'thumb_up_alt' : 'thumb_up_off_alt'
  const thumbDown = question.rating === RatingEnum.NEGATIVE ? 'red' : 'gray'
  const thumbDownIcon = question.rating === RatingEnum.NEGATIVE ? 'thumb_down_alt' : 'thumb_down_off_alt'

  const chatType = chatSession ? chatSession.type : 'loading'

  const answerRaw = question.answers[selectedAnswer]?.answer
  const answer = question.loading ? answerRaw || '*Thinking...*' : answerRaw || '*No answer found*'

  const handleEditAnswer = (question: IQuestion) => {
    setCurrentQuestion(question)
    setShowEditModal(true)
  }

  const copyAnswer = () => {
    navigator.clipboard.writeText(answer)
    appInsights.trackEvent({ name: 'Response Copy' })

    addToast({
      type: 'success',
      title: t('popup_toast'),
      message: t('popup_mess11'),
      timeout: 5000,
      timeLabel: t('popup_toast_timelabel'),
    })
  }

  const handleRateAnswer = async (question: IQuestion, rating: number) => {
    if (!chatSession) return

    const newQuestion = { ...question }

    if (newQuestion.rating === RatingEnum.NO_RATING || newQuestion.rating !== rating) {
      newQuestion.rating = rating

      if (rating === RatingEnum.NEGATIVE && ChatTypeActions[chatType].feedback) {
        setCurrentQuestion(newQuestion)
        setShowFeedbackModal(true)
      }
    } else newQuestion.rating = RatingEnum.NO_RATING

    if (appInsights && newQuestion.rating === RatingEnum.POSITIVE) {
      appInsights.trackEvent({ name: 'Thumbs Up' })
    }

    if (appInsights && newQuestion.rating === RatingEnum.NEGATIVE) {
      appInsights.trackEvent({ name: 'Thumbs Down' })
    }

    const updatedQuestions = chatSession.questions.map((q) => {
      if (q.rowKey !== newQuestion.rowKey) return q

      return newQuestion
    })

    const result = await updateQuestion({
      chatId: newQuestion.chatId,
      rowKey: newQuestion.rowKey,
      rating: newQuestion.rating,
    })

    if (!result?.success) {
      addToast({
        type: 'error',
        title: t('popup_toast2'),
        message: t('popup_mess12'),
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })
      return
    }

    addToast({
      type: 'success',
      title: t('popup_toast'),
      message: t('popup_mess13'),
      timeout: 5000,
      timeLabel: t('popup_toast_timelabel'),
    })

    setChatSession({
      ...chatSession,
      questions: updatedQuestions,
    })
  }

  const timeTaken = question.timeTaken ? `${question.timeTaken / 1000}s` : t('unknown')

  const questionDetails = (
    <div className={Style.infoPopup}>
      <p>
        <b>Id:</b> {question.rowKey}
        <br />
        <b>{t('created')}</b> {dateToString(question.createdAt || '', 'dd/MM/yyyy HH:mm')}
        <br />
        <b>{t('finish_reason')}</b> {question.finishReason || t('unknown')}
        <br />
        <b>{t('token_used')}</b> {question.usedTokens || t('unknown')}
        <br />
        <b>{t('time_taken')}</b> {timeTaken}
        <br />
        <b>{t('model')}</b> {question.model || t('unknown')}
      </p>
    </div>
  )

  const editPopup = (
    <div className={Style.infoPopup}>
      <p>{answerRaw ? t('edit_answer') : t('missing_answer')}</p>
    </div>
  )

  const popupWrapper = (text: string) => (
    <div className={Style.infoPopup}>
      <p>{text}</p>
    </div>
  )
  return (
    <div className={Style.actions}>
      {ChatTypeActions[chatType].info && (
        <Tooltip show={questionDetails} cssClass='answer-actions'>
          <Button type='icon-square' default icon='info' size='small' cssClass={Style.actionButton} />
        </Tooltip>
      )}
      {ChatTypeActions[chatType].edit && (
        <Tooltip show={editPopup} cssClass='answer-actions'>
          <Button
            type='icon-square'
            default
            icon='edit'
            size='small'
            onClick={() => handleEditAnswer(question)}
            cssClass={Style.actionButton}
            disabled={question.loading || !answerRaw}
          />
        </Tooltip>
      )}
      {ChatTypeActions[chatType].clipboard && (
        <Tooltip show={popupWrapper(t('copy'))} cssClass='answer-actions'>
          <Button
            type='icon-square'
            default
            icon='content_paste'
            size='small'
            onClick={copyAnswer}
            cssClass={Style.actionButton}
            disabled={question.loading}
          />
        </Tooltip>
      )}
      {ChatTypeActions[chatType].rating && (
        <>
          <Tooltip show={popupWrapper(t('thumb_up'))} cssClass='answer-actions'>
            <Button
              type='icon-square'
              default
              icon={thumbUpIcon}
              size='small'
              style={{ color: thumbUp }}
              onClick={() => handleRateAnswer(question, RatingEnum.POSITIVE)}
              cssClass={Style.actionButton}
              disabled={question.loading}
            />
          </Tooltip>
          <Tooltip show={popupWrapper(t('thumb_down'))} cssClass='answer-actions'>
            <Button
              type='icon-square'
              default
              icon={thumbDownIcon}
              size='small'
              style={{ color: thumbDown }}
              onClick={() => handleRateAnswer(question, RatingEnum.NEGATIVE)}
              cssClass={Style.actionButton}
              disabled={question.loading}
            />
          </Tooltip>
        </>
      )}
    </div>
  )
}

export default AnswerActions
