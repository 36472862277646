import { FC } from 'react'
import { Checkbox } from '@aurecon-creative-technologies/styleguide'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import Style from '../../styles/common/RecallCheckbox.module.sass'
import classNames from 'classnames'

export interface IRecallCheckboxProps {
  chatType: number
  checked?: boolean
  onChange?: (value: boolean) => void
  cssClass?: string
  disabled?: boolean
}

const RecallCheckbox: FC<IRecallCheckboxProps> = ({ chatType, checked, onChange, cssClass, disabled }) => {
  const chatTypeClasses = () => {
    return classNames(cssClass, {
      [Style.bamboo]: chatType === ChatTypeEnum.BAMBOO,
    })
  }

  return <Checkbox cssClass={chatTypeClasses()} checked={checked} onChange={onChange} disabled={disabled} />
}

export default RecallCheckbox
