import { FC } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Button } from '@aurecon-creative-technologies/styleguide'

import { SelectedFocus, ShowExtendedInput } from '../stores/AppStore'

import Style from '../styles/FocusButton.module.sass'

interface IFocusButtonProps {
  loading?: boolean
}

const FocusButton: FC<IFocusButtonProps> = (props) => {
  const [showExtendedInput, setShowExtendedInput] = useRecoilState(ShowExtendedInput)
  const selectedFocus = useRecoilValue(SelectedFocus)
  const { loading } = props

  return (
    <>
      {!!selectedFocus.length && <div className={Style.focusDot}></div>}
      <Button
        type='icon-round'
        icon={showExtendedInput ? 'expand_more' : 'expand_less'}
        cssClass={Style.fileAttachButton}
        onClick={() => setShowExtendedInput((value) => !value)}
        disabled={loading}
      />
    </>
  )
}

export default FocusButton
