import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import SwaggerUI from 'swagger-ui-react'

import { getSwaggerDef } from '../api/SwaggerService'
import { appInsights } from '../api/AppInsights'
import Page from '../components/Page'

import Style from '../styles/ApiDocs.module.sass'
import 'swagger-ui-react/swagger-ui.css'
import { Loader } from '@aurecon-creative-technologies/styleguide'

const ApiDocs: FC = () => {
  const [apiDocs, setApiDocs] = useState<null | string>(null)
  const [loading, setLoading] = useState(true)
  const { version } = useParams()

  if (appInsights) appInsights.trackPageView({ name: 'ApiDocs' })

  useEffect(() => {
    const asyncFunc = async () => {
      setLoading(true)
      setApiDocs(null)
      const apiDocs = await getSwaggerDef({ version: version || 'v1' })
      if (apiDocs?.data?.docs) {
        setApiDocs(apiDocs.data.docs)
      }

      setLoading(false)
    }

    asyncFunc()
  }, [version])

  return (
    <Page menu>
      <div className={Style.wrapper}>
        {loading && <Loader label='Loading API documentation' />}
        {!apiDocs && !loading && (
          <>
            <h1>Recall Application API</h1>
            <p>API version '{version}' not found</p>
          </>
        )}
        {apiDocs && !loading && <SwaggerUI spec={apiDocs} />}
      </div>
    </Page>
  )
}

export default ApiDocs
