import { FC } from 'react'
import { Icon } from '@aurecon-creative-technologies/styleguide'

import Style from '../styles/Chat.module.sass'

const ChatNotFound: FC = () => {
  return (
    <div className={Style.message}>
      <p>
        <Icon type='warning' size='60px' />
      </p>
      <p>Chat not found or you have no permissions.</p>
    </div>
  )
}

export default ChatNotFound
