import { FC, useMemo } from 'react'

import { ReactComponent as RecallMenuIcon } from '../../src/assets/Recall_menu_icon.svg'
import { ReactComponent as GPTMenuIcon } from '../../src/assets/ChatGPT_menu_icon.svg'
import { ReactComponent as WinWiseLogo } from '../../src/assets/WinWise_logo.svg'
import { ReactComponent as CRALogo } from '../../src/assets/CRA_logo.svg'
import { ReactComponent as BHPLogo } from '../../src/assets/BHP_logo.svg'

import { appInsights } from '../api/AppInsights'
import Page from '../components/Page'
import { AppTile } from '../components/AppTile'
import { useChangeChat } from '../hooks/useChangeChat'
import { AppPill } from '../components/AppPill'
import { AppColour } from '../enums/AppColourConstants'
import { AppShortcut } from '../components/AppShortcut'
import { FeatureFlagEnum } from '../enums/FeatureFlagEnum'
import { useShowFeature } from '../hooks/useShowFeature'

import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/skyblue'
import '@splidejs/react-splide/css/sea-green'
import '@splidejs/react-splide/css/core'
import { useLanguages } from '../hooks/useLanguages'
import LoadingScreen from '../components/LoadingScreen'

import AppSlideViewer from '../components/AppSlideViewer'
import { AppSlidePositionEnum } from '../enums/AppSlidePosition'

import Style from '../styles/Home.module.sass'

const Home: FC = () => {
  const { openRecallChat, openChatGPT, openWinWise, openBamboo, openCustomRecallApp, openBHPStandards } =
    useChangeChat()
  const showBambooChat = useShowFeature(FeatureFlagEnum.ShowBambooChat)
  const showCustomRecallChat = useShowFeature(FeatureFlagEnum.ShowCustomRecallApp)
  const showBHPStandards = useShowFeature(FeatureFlagEnum.ShowBHPStandardsApp)
  const showHomepageSlides = useShowFeature(FeatureFlagEnum.ShowHomepageSlides)

  const { t, i18n } = useLanguages()

  if (appInsights) appInsights.trackPageView({ name: 'Home' })

  const appSlides = useMemo(() => {
    return showHomepageSlides.enabled ? (
      <>
        <AppSlideViewer position={AppSlidePositionEnum.MAIN} />
        <div className={Style.small}>
          <AppSlideViewer position={AppSlidePositionEnum.LEFT} />
          <AppSlideViewer position={AppSlidePositionEnum.RIGHT} />
        </div>
      </>
    ) : null
  }, [showHomepageSlides.enabled])

  const appTiles = useMemo(() => {
    return (
      <>
        <h1 className={Style.appTilesTitle}>{t('home_header')}</h1>

        <div className={Style.tiles}>
          <AppTile AppIcon={RecallMenuIcon} content={t('recall_header')} onClick={openRecallChat} />
          <AppTile AppIcon={GPTMenuIcon} content={t('securegpt_header')} onClick={openChatGPT} />
          <AppTile AppIcon={WinWiseLogo} content={t('winwise_header')} onClick={openWinWise} />
          {showCustomRecallChat.enabled && (
            <AppTile AppIcon={CRALogo} content={t('custom_app_header')} onClick={openCustomRecallApp} />
          )}
          {showBHPStandards.enabled && (
            <AppTile AppIcon={BHPLogo} cssClass={Style.bhpLogo} content={t('bhp_header')} onClick={openBHPStandards} />
          )}
        </div>
      </>
    )
  }, [
    openBHPStandards,
    openChatGPT,
    openCustomRecallApp,
    openRecallChat,
    openWinWise,
    showBHPStandards.enabled,
    showCustomRecallChat.enabled,
    t,
  ])
  const appPills = useMemo(() => {
    return !showHomepageSlides.enabled ? (
      <>
        <h1>{t('home_header2')}</h1>

        <div className={Style.pills}>
          <AppPill content={t('recall_pin')} onClick={openRecallChat} colour={AppColour.RECALL_CHAT} />
          <AppPill content={t('securegpt_pin')} onClick={openChatGPT} colour={AppColour.GPT_CHAT} />
          <AppPill content={t('winwise_pin')} onClick={openWinWise} colour={AppColour.WINWISE} />
          {showBambooChat.enabled && (
            <AppPill content={t('bamboo_pin')} onClick={openBamboo} colour={AppColour.BAMBOO} />
          )}
          {showCustomRecallChat.enabled && (
            <AppPill content={t('cra_pin')} onClick={openCustomRecallApp} colour={AppColour.CUSTOM_RECALL_APP} />
          )}
          {showBHPStandards.enabled && (
            <AppPill content={t('bhp_pin')} onClick={openBHPStandards} colour={AppColour.BHP_STANDARDS} />
          )}
        </div>
      </>
    ) : null
  }, [
    showHomepageSlides.enabled,
    t,
    openRecallChat,
    openChatGPT,
    openWinWise,
    showBambooChat.enabled,
    openBamboo,
    showCustomRecallChat.enabled,
    openCustomRecallApp,
    showBHPStandards.enabled,
    openBHPStandards,
  ])

  const appShortcuts = useMemo(() => {
    return !showHomepageSlides.enabled ? (
      <>
        <hr className={Style.divider} />

        <h2>{t('home_header3')}</h2>
        <div className={Style.shortcuts}>
          <AppShortcut AppIcon={RecallMenuIcon} content='Recall Chat' onClick={openRecallChat} />
          <AppShortcut AppIcon={GPTMenuIcon} content='Secure ChatGPT' onClick={openChatGPT} />
          <AppShortcut AppIcon={WinWiseLogo} content='WinWise' onClick={openWinWise} />
          {showCustomRecallChat.enabled && (
            <AppShortcut AppIcon={CRALogo} content='Custom Recall App' onClick={openCustomRecallApp} />
          )}
          {showBHPStandards.enabled && <AppShortcut AppIcon={BHPLogo} content='Standards' onClick={openBHPStandards} />}
        </div>
      </>
    ) : null
  }, [
    openBHPStandards,
    openChatGPT,
    openCustomRecallApp,
    openRecallChat,
    openWinWise,
    showBHPStandards.enabled,
    showCustomRecallChat.enabled,
    showHomepageSlides.enabled,
    t,
  ])

  if (!i18n)
    return (
      <Page>
        <LoadingScreen text={t('loading_translation')} />
      </Page>
    )
  return (
    <Page menu contentWrapper noGrow>
      <>
        {appSlides}
        {appTiles}
        {appPills}
        {appShortcuts}
      </>
    </Page>
  )
}

export default Home
