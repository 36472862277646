import { FC } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import Login from '../pages/Login'
import TermsAndConditions from '../pages/TermsAndConditions'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import CookiesPolicy from '../pages/CookiesPolicy'

export const publicRoutes = [
  <Route key='home_public' path='/login' element={<Login />} />,
  <Route key='terms' path='/termsandconditions' element={<TermsAndConditions />} />,
  <Route key='privacy' path='/privacy' element={<PrivacyPolicy />} />,
  <Route key='cookies' path='/cookies' element={<CookiesPolicy />} />,
]

const PublicRoutes: FC = () => {
  const location = useLocation()
  return (
    <Routes>
      {publicRoutes}
      <Route path='*' element={<Navigate to={{ pathname: '/login' }} state={{ from: location }} />} />
    </Routes>
  )
}

export default PublicRoutes
