import { FC } from 'react'
import { useRecoilValue } from 'recoil'
import { Modal } from '@aurecon-creative-technologies/styleguide'

import { ShowDownloadFileModal } from '../../stores/AppStore'

const FileDownloadModal: FC = () => {
  const showDownloadFileModal = useRecoilValue(ShowDownloadFileModal)

  return (
    <Modal
      isShowing={showDownloadFileModal}
      isCloseButton={false}
      onClose={() => {
        /* do nothing */
      }}
    >
      <h2>Downloading...</h2>
      <p>Please wait, the file is being downloaded.</p>
    </Modal>
  )
}

export default FileDownloadModal
