import { getAsync } from '../helpers/apiRequest'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { IGetQuestionsResponseModel } from '../models/api/IGetQuestionsModel'
import { IResponse } from '../models/api/IResponse'
import { TokenExpiryWrapper } from './TokenManager'

interface IGetFeedbcakRequestModel extends IBodyRequestModel {
  days: number
  type: string
}

export const getFeedback = TokenExpiryWrapper(
  (request: IGetFeedbcakRequestModel): Promise<IResponse<IGetQuestionsResponseModel[]>> =>
    getAsync(`/v1/feedback?days=${request.days}&type=${request.type}`, request),
  [],
  null,
)
