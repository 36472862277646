import { FC, SVGProps } from 'react'
import Style from '../styles/AppShortcut.module.sass'

interface IAppShortcutProps {
  AppIcon: FC<SVGProps<SVGSVGElement>> | null
  content: string
  onClick: () => void
}

export const AppShortcut: FC<IAppShortcutProps> = (props) => {
  const { AppIcon, content, onClick } = props

  return (
    <div className={Style.shortcut} onClick={onClick} role='none'>
      {AppIcon && <AppIcon role='image' aria-label={content} className={Style.logo} />}
      <div className={Style.content}>{content}</div>
    </div>
  )
}
