import React, { FC, useEffect, useContext, useState, useRef } from 'react'
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import classNames from 'classnames'
import { Container, Icon, ThemeContext, ThemeEnum } from '@aurecon-creative-technologies/styleguide'

import Menu from './Menu'
import PageHeader from './PageHeader'
import QuestionBox from './QuestionBox'
import { BannerInfo, ChatType, FullScreen, Language, ScrollChat, SystemBannersLoader } from '../stores/AppStore'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'

import Style from '../styles/Page.module.sass'
import { ISystemBannerInfo } from '../api/AppService'
import debounce from 'debounce'
import { useAuth0 } from '@auth0/auth0-react'
import { getTextFromHTML } from '../helpers/utils'
import { LanguageEnum } from '../enums/LanguageEnum'

export interface IPageProps {
  pageTitle?: string
  menu?: boolean
  children?: JSX.Element[] | JSX.Element | React.ReactNode
  contentWrapper?: boolean
  contentWrapperWide?: boolean
  noGrow?: boolean
  contentsRef?: React.RefObject<HTMLDivElement>
  cssClassName?: string
}

const PAGE_SCROLL_ADJUSTMNET = 1000

const Page: FC<IPageProps> = (props) => {
  const { isLoading } = useAuth0()
  const { theme } = useContext(ThemeContext)
  const language = useRecoilValue(Language)
  const chatType = useRecoilValue(ChatType)
  const fullScreen = useRecoilValue(FullScreen)
  const scrollChat = useRecoilValue(ScrollChat)
  const systemBannersLoader = useRecoilValueLoadable(SystemBannersLoader)
  const [systemBanner, setSystemBanner] = useState<ISystemBannerInfo>()
  const [triggerScroll, setTriggerScroll] = useState(true)
  const [bannerInfo, setBannerInfo] = useRecoilState(BannerInfo)
  const bannerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!props.contentsRef?.current || !triggerScroll) return

    props.contentsRef.current.scrollTo({
      top: props.contentsRef.current.scrollHeight + PAGE_SCROLL_ADJUSTMNET,
      behavior: 'smooth',
    })
  }, [props.contentsRef, scrollChat, triggerScroll])

  useEffect(() => {
    if (systemBannersLoader.state !== 'hasValue' || !systemBannersLoader.contents) return
    let banner = systemBannersLoader.contents.find((c) => c.language === language)
    const hasMessage = getTextFromHTML(banner?.message || '')
    if (!hasMessage) {
      banner = systemBannersLoader.contents.find((c) => c.language === LanguageEnum.ENGLISH)
    }

    if (banner) setSystemBanner(banner)
  }, [language, systemBannersLoader.contents, systemBannersLoader.state])

  const message = systemBanner?.message

  const pageContentsClasses = classNames({
    [Style.pageContents]: true,
    [Style.fullscreen]: fullScreen,
    [Style.pageWideContents]: props.contentWrapperWide,
    [Style.noGrow]: props.noGrow,
  })

  const pageClasses = classNames({
    [Style.page]: true,
    pageThemeDark: theme === ThemeEnum.DARK,
    pageFontOpenSans: language === 'vi',
  })

  const scrolling = debounce((ev: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = ev.target as HTMLDivElement
    const elms: HTMLDivElement[] = Array.prototype.slice.call(document.querySelectorAll('[id^=qa-holder-]'))
    if (!elms.length) return
    const pos = target.scrollTop + target.offsetHeight
    const notBottom = elms.some((e) => e.offsetTop < pos && pos < e.offsetTop + e.clientHeight)
    setTriggerScroll(!notBottom)
  }, 500)

  const enableSystemBanner = systemBanner?.enabled && !bannerInfo.closed && !isLoading

  useEffect(() => {
    const bannerDiv = bannerRef.current
    if (!bannerDiv || !enableSystemBanner) return

    setBannerInfo((b) => ({ ...b, height: bannerDiv.offsetHeight + 20 }))
  }, [enableSystemBanner, setBannerInfo])

  const renderSystemBanner = () => {
    return (
      <div className={Style.bannerContainer} ref={bannerRef}>
        <div className={Style.message} dangerouslySetInnerHTML={{ __html: message || ' ' }} />
        <div className={Style.iconHolder}>
          <Icon cssClass={Style.icon} type='close' onClick={() => setBannerInfo({ height: 0, closed: true })} />
        </div>
      </div>
    )
  }

  return (
    <div className={pageClasses}>
      <Container cssClass={Style.container}>
        {enableSystemBanner && renderSystemBanner()}
        <div
          className={Style.columns}
          style={{ paddingTop: enableSystemBanner ? `${bannerInfo.height}px` : undefined }}
        >
          {props.menu && <Menu />}
          <div
            className={classNames(Style.mainPageWrapper, props.cssClassName)}
            ref={props.contentsRef}
            onScrollCapture={scrolling}
          >
            <PageHeader pageTitle={props.pageTitle} />
            {props.contentWrapper ? <div className={pageContentsClasses}>{props.children}</div> : <>{props.children}</>}
          </div>
          {chatType !== null && chatType !== ChatTypeEnum.BAMBOO && <QuestionBox />}
        </div>
      </Container>
    </div>
  )
}

export default Page
