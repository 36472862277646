// eslint-disable-next-line @typescript-eslint/ban-types
export type IResponseTypes = object | string | boolean
export interface IResponse<T extends IResponseTypes> {
  success: boolean
  message?: string
  data?: T | null
  headers?: Headers
  status?: number
}
export const ResponseData = <T extends IResponseTypes>(response: IResponse<T> | null): T | null => {
  if (response && response.success && response.data) return response.data
  return null
}

export const WrapIResponse = <T extends IResponseTypes>(
  data: T | null,
  success?: boolean,
  message?: string,
  headers?: Headers,
): IResponse<T> => {
  return { data, success: success || !!data, message, headers }
}

export const failedResponse = {
  data: null,
  success: false,
}
