import { TFunction, i18n } from 'i18next'
import { useTranslation } from 'react-i18next'

import { useRecoilValueLoadable } from 'recoil'
import { AppLanguages, LoginLanguages } from '../stores/AppStore'
import { useEffect, useState } from 'react'
import { fallbackLanguage } from '../helpers/i18n'

export interface ILangJson {
  lang: string
  name: string
  flag: string
  def: {
    [code: string]: {
      translation: {
        [key: string]: string
      }
    }
  }
}

export interface ILanguageDefinitions {
  id: string
  flag: string
  name: string
}

export const useLanguages = (
  login = false,
): { languages: ILanguageDefinitions[] | null; i18n: i18n | null; t: TFunction<'translation', undefined> } => {
  const [languages, setLanguages] = useState<ILanguageDefinitions[] | null>(null)
  const appLanguages = useRecoilValueLoadable(login ? LoginLanguages : AppLanguages)
  const { i18n, t } = useTranslation()

  useEffect(() => {
    if (appLanguages.state != 'hasValue' || languages) return

    if (!appLanguages.contents) {
      setLanguages([])
      if (fallbackLanguage) i18n.addResourceBundle('en', 'translation', fallbackLanguage)
      return
    }
    try {
      const langs: ILangJson[] = appLanguages.contents
      const dropdownItems: ILanguageDefinitions[] = langs.map((l) => {
        i18n.addResourceBundle(l.lang, 'translation', { ...fallbackLanguage, ...l.def })
        return {
          id: l.lang,
          name: l.name,
          flag: l.flag,
        }
      })
      setLanguages(dropdownItems)
    } catch (error) {
      console.log(error)
    }
  }, [appLanguages.contents, appLanguages.state, i18n, languages, login])

  if (!languages || !i18n) return { languages: null, i18n: null, t }

  return { languages, i18n, t }
}
