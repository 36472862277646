import { FC } from 'react'
import { Icon } from '@aurecon-creative-technologies/styleguide'

import Style from '../styles/History.module.sass'

const NoChatHistory: FC = () => {
  return (
    <div className={Style.message}>
      <p>
        <Icon type='auto_awesome' size='60px' />
      </p>
      <p>Your chat history is empty.</p>
    </div>
  )
}

export default NoChatHistory
