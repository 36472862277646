import { deleteAsync, getAsync, postMultiFormDataAsync } from '../helpers/apiRequest'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { IAppSlide, IDeleteAppSlideRequestModel, ISaveAppSlideRequestModel } from '../models/api/IHomepageModels'
import { IResponse } from '../models/api/IResponse'
import { TokenExpiryWrapper } from './TokenManager'

export const getAppSlides = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<IAppSlide[]>> => getAsync('/v1/homepage', request),
  [],
  null,
)

export const deleteAppSlide = TokenExpiryWrapper(
  (request: IDeleteAppSlideRequestModel): Promise<IResponse<IDeleteAppSlideRequestModel>> =>
    deleteAsync('/v1/homepage', request),
  [],
  null,
)

export const saveAppSlide = TokenExpiryWrapper(
  (request: ISaveAppSlideRequestModel): Promise<IResponse<boolean>> => {
    const formData = new FormData()
    // insert attachments
    request.file && formData.append('file', request.file)
    Object.keys(request)
      .filter((key) => key !== 'token' && key !== 'file')
      .forEach((key) => {
        return formData.append(key, request[key] ?? '')
      })

    return postMultiFormDataAsync('/v1/homepage/upload', request.token ?? '', formData)
  },

  [],
  null,
)
