export const CRATypeEnum = {
  DRAFT: 1,
  PUBLISHED: 2,
  SHAREDWITHME: 3,
}

export const CRATypeLabel = {
  [CRATypeEnum.DRAFT]: 'Draft',
  [CRATypeEnum.PUBLISHED]: 'Published',
  [CRATypeEnum.SHAREDWITHME]: 'Shared with me',
}

export const CRATypeColor = {
  [CRATypeEnum.DRAFT]: 1,
  [CRATypeEnum.PUBLISHED]: 2,
  [CRATypeEnum.SHAREDWITHME]: 4,
}
