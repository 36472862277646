import { FC, useEffect, useState } from 'react'
import {
  Checkbox,
  FormInput,
  Grid,
  IButtonProps,
  Icon,
  Modal,
  Tooltip,
  useToast,
} from '@aurecon-creative-technologies/styleguide'
import { useRecoilState } from 'recoil'
import { ChatSession, CurrentQuestion, ShowFeedbackModal } from '../../stores/AppStore'
import { updateQuestion } from '../../api/QuestionService'
import { FeedbackTypeEnum } from '../../enums/FeedbackTypeEnum'
import { useLanguages } from '../../hooks/useLanguages'

import Style from '../../styles/AnswerFeedbackModal.module.sass'

const AnswerFeedbackModal: FC = () => {
  const [currentQuestion, setCurrentQuestion] = useRecoilState(CurrentQuestion)
  const [showFeedbackModal, setShowFeedbackModal] = useRecoilState(ShowFeedbackModal)
  const [chatSession, setChatSession] = useRecoilState(ChatSession)
  const [feedbackText, setFeedbackText] = useState('')
  const [feedbackType, setFeedbackType] = useState(FeedbackTypeEnum.NO_FEEDBACK)
  const { addToast } = useToast()
  const { t } = useLanguages()

  useEffect(() => {
    setFeedbackText(currentQuestion?.feedback || '')
    setFeedbackType(currentQuestion?.feedbackType || FeedbackTypeEnum.NO_FEEDBACK)
  }, [currentQuestion])

  const handleModalClose = () => {
    setFeedbackText('')
    setCurrentQuestion(null)
    setShowFeedbackModal(false)
  }

  const handleFeedbackType = (type: number) => {
    const newType = (feedbackType & type) === type ? feedbackType - type : feedbackType + type

    setFeedbackType(newType)
  }

  const handleFeedbackShare = async () => {
    if (!currentQuestion || !chatSession) return

    const result = await updateQuestion({
      chatId: currentQuestion.chatId,
      rowKey: currentQuestion.rowKey,
      feedbackType: feedbackType,
      feedback: feedbackText,
    })

    if (!result?.success) {
      addToast({
        type: 'error',
        title: t('popup_toast2'),
        message: t('popup_mess17'),
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })
      return
    }
    addToast({
      type: 'success',
      title: t('popup_toast'),
      message: t('popup_mess18'),
      timeout: 5000,
      timeLabel: t('popup_toast_timelabel'),
    })

    const updatedQuestions = chatSession.questions.map((q) => {
      if (q.rowKey !== currentQuestion.rowKey) return q

      return {
        ...currentQuestion,
        feedback: feedbackText,
        feedbackType: feedbackType,
      }
    })

    setChatSession({
      ...chatSession,
      questions: updatedQuestions,
    })
    handleModalClose()
  }

  const modalActions = [
    {
      label: t('discard'),
      type: 'secondary',
      onClick: handleModalClose,
    },
    {
      label: t('share'),
      onClick: handleFeedbackShare,
      disabled: !feedbackText.trim() && !feedbackType,
    },
  ] as IButtonProps[]

  return (
    <Modal isShowing={showFeedbackModal} onClose={handleModalClose} actions={modalActions}>
      <h2>Feedback</h2>
      <p className={Style.label}>Quick feedback</p>
      <Grid row gap={12} cssClass={Style.form}>
        <Grid item xs={12} md={6} lg={4}>
          <Checkbox
            label='Coherence'
            checked={(feedbackType & FeedbackTypeEnum.COHERENCE) === FeedbackTypeEnum.COHERENCE}
            onChange={() => handleFeedbackType(FeedbackTypeEnum.COHERENCE)}
          />{' '}
          <Tooltip show='No logical consistency, e.g. lacking a topic or missing a step in a thought process.'>
            <Icon type='info' cssClass={Style.checkboxInfo} />
          </Tooltip>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Checkbox
            label='Accuracy'
            checked={(feedbackType & FeedbackTypeEnum.ACCURACY) === FeedbackTypeEnum.ACCURACY}
            onChange={() => handleFeedbackType(FeedbackTypeEnum.ACCURACY)}
          />
          <Tooltip show='Information to answer the question is not provided in the context or is irrelevant / wrong.'>
            <Icon type='info' cssClass={Style.checkboxInfo} />
          </Tooltip>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Checkbox
            label='Consistency'
            checked={(feedbackType & FeedbackTypeEnum.CONSISTENCY) === FeedbackTypeEnum.CONSISTENCY}
            onChange={() => handleFeedbackType(FeedbackTypeEnum.CONSISTENCY)}
          />{' '}
          <Tooltip show='Information contained in the answer that is not provided in the context or question.'>
            <Icon type='info' cssClass={Style.checkboxInfo} />
          </Tooltip>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Checkbox
            label='Redundancy'
            checked={(feedbackType & FeedbackTypeEnum.REDUNDANCY) === FeedbackTypeEnum.REDUNDANCY}
            onChange={() => handleFeedbackType(FeedbackTypeEnum.REDUNDANCY)}
          />{' '}
          <Tooltip show='Explanation / answer contains redundant information which may or may not be factual.'>
            <Icon type='info' cssClass={Style.checkboxInfo} />
          </Tooltip>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Checkbox
            label='Sensitive'
            checked={(feedbackType & FeedbackTypeEnum.SENSITIVE) === FeedbackTypeEnum.SENSITIVE}
            onChange={() => handleFeedbackType(FeedbackTypeEnum.SENSITIVE)}
          />{' '}
          <Tooltip show='Contains sensitive information'>
            <Icon type='info' cssClass={Style.checkboxInfo} />
          </Tooltip>
        </Grid>
      </Grid>

      <Grid row gap={12}>
        <Grid item xs={12}>
          <FormInput
            label='Feedback Details (optional)'
            multiline
            multilineLimit={200}
            placeholder={`Let us know why you feel that the answer you received wasn't as you expected...`}
            value={feedbackText}
            onChange={setFeedbackText}
            cssClass={Style.input}
          />
        </Grid>
      </Grid>
    </Modal>
  )
}

export default AnswerFeedbackModal
