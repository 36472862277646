import JoditEditor, { IJoditEditorProps, Jodit } from 'jodit-react'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import LimitedCharactersLeft from './LimitedCharacters'
import classNames from 'classnames'
import { getTextFromHTML } from '../../helpers/utils'

import Style from '../../styles/common/RecallJoditEditor.module.sass'

interface IReactJoditEditorProps extends IJoditEditorProps {
  maxLength?: number
  required?: boolean
  error?: string
  label?: string
  onTextChange?: (text: string) => void
}

const RecallJoditEditor: FC<IReactJoditEditorProps> = (props) => {
  const { config, onChange, value, className, onBlur, maxLength, error, onTextChange, required, label } = props
  const editor = useRef<Jodit>(null)
  const defaultValueSet = useRef<boolean>(false)
  const [textValue, setTextValue] = useState('')
  const [initValue, setInitValue] = useState('  ')

  const onJoditChange = useCallback(
    (content: string) => {
      const text = getTextFromHTML(content)
      setTextValue(text)
      onChange?.(content)
      onTextChange?.(text)
    },
    [onChange, onTextChange],
  )

  const loadConfig = useMemo(() => {
    return { ...config }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (value && editor.current && !defaultValueSet.current) {
      setInitValue(value)
      onJoditChange(value)
      defaultValueSet.current = true
    } else if (value !== initValue) {
      setInitValue(value)
    }
  }, [initValue, onJoditChange, value])

  const wrapperClasses = classNames({
    'field-wrapper': true,
    [Style.joditHolder]: true,
    [Style.error]: !!error,
  })

  return (
    <div className={wrapperClasses}>
      <label>
        {label && (
          <span className='input-label'>
            {label} {required && <span className='input-required'>*</span>}
          </span>
        )}
        <JoditEditor
          value={initValue}
          ref={editor}
          config={loadConfig}
          onChange={onJoditChange}
          onBlur={onBlur}
          className={className}
        />
        {error ? (
          <span className='input-error'>{error}</span>
        ) : (
          maxLength && <LimitedCharactersLeft maxLength={maxLength} value={textValue} />
        )}
      </label>
    </div>
  )
}

export default RecallJoditEditor
