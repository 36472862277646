import { FC } from 'react'
import PDFViewer from '../components/PDFViewer'
import { useParams } from 'react-router-dom'
import nextId from 'react-id-generator'

const PdfDocumentViewer: FC = () => {
  const { filename } = useParams()

  if (!filename) return null

  const item = localStorage.getItem(filename)
  return <PDFViewer file={item ?? ''} showOnlyAsPreview={false} viewerId={`viewer-${filename}-${nextId()}`} />
}

export default PdfDocumentViewer
