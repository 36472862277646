import { FC, useMemo, useState } from 'react'
import { Button, Grid, IOption, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import RecallModal from '../common/RecallModal'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import { FullScreen } from '../../stores/AppStore'
import { useRecoilValue } from 'recoil'
import Style from '../../styles/NomicModal.module.sass'
import UserComboBox from '../common/UserComboBox'
import { ICustomAppResponseModel, ICustomAppShareResponseModel } from '../../models/api/ICreateChatModels'
import { format, parseISO } from 'date-fns'
import { twelveHourFormat } from '../../config/config'
import { sortArrBy } from '../../helpers/utils'
import { useLanguages } from '../../hooks/useLanguages'

interface IManageAccessModalProps {
  open: boolean
  onSave: (users: IOption[], cra: ICustomAppResponseModel) => Promise<void>
  onRevoke: (customAppId: string, userId: string, name: string) => Promise<void>
  onClose: () => void
  cra: ICustomAppResponseModel
  customAppShareList: ICustomAppShareResponseModel[]
  loading?: boolean
}

const defaultSortOrder = {
  name: 'none' as sortType,
  updatedAt: 'desc' as sortType,
}

type sortType = 'none' | 'desc' | 'asc' | undefined

interface IManageAccessSortOrder {
  name: sortType
  updatedAt: sortType
}

const ManageAccessModal: FC<IManageAccessModalProps> = (props) => {
  const { open, onClose, loading, cra, customAppShareList, onSave, onRevoke } = props
  const fullScreen = useRecoilValue(FullScreen)
  const [selectedUsers, setSelectedUsers] = useState<IOption[]>([])
  const [deletingUser, setDeletingUser] = useState('')
  const [sortOrder, setSortOrder] = useState<IManageAccessSortOrder>(defaultSortOrder)
  const { t } = useLanguages()

  const handleModalClose = () => {
    onClose()
  }

  const handleAddAccess = async () => {
    await onSave(selectedUsers, cra)
    setSelectedUsers([])
  }

  const handleRevokeAccess = async (customAppId: string, userId: string, name: string) => {
    setDeletingUser(userId)
    await onRevoke(customAppId, userId, name)
    setDeletingUser('')
  }

  const onSort = (field: string, sort: string) => {
    const newOrder = {
      ...defaultSortOrder,
      updatedAt: sort === 'none' && field !== 'updatedAt' ? 'desc' : ('none' as sortType),
      [field]: sort,
    }
    setSortOrder(newOrder)
  }

  const filteredAndSortedList = useMemo(() => {
    const foundSortKey = (Object.keys(sortOrder) as [keyof IManageAccessSortOrder]).find(
      (key) => sortOrder[key] === 'asc' || sortOrder[key] === 'desc',
    )

    const sortKey = foundSortKey ?? 'updatedAt'
    const order = sortOrder[sortKey]

    return sortArrBy(order as string, customAppShareList, sortKey) as ICustomAppShareResponseModel[]
  }, [customAppShareList, sortOrder])

  return (
    <RecallModal
      chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
      isShowing={open}
      onSave={handleAddAccess}
      onClose={handleModalClose}
      disabled={!selectedUsers.length}
      size={fullScreen ? 'medium' : 'small'}
      labelYes={t('share')}
      loadingYes={loading}
    >
      <div className={Style.contentWrapperNomicModal}>
        <h2>{t('manage_access')}</h2>
        <p className={Style.propertyDescriptionManageAccess}> {t('manage_access_desc', { appName: cra.name })}</p>
        <Grid row gap={12}>
          <UserComboBox
            onSelectedItem={setSelectedUsers}
            selectedItems={selectedUsers}
            excludeIds={[cra.ownerId, ...customAppShareList.map((c) => c.auth0UserId)]}
            label={t('select_user')}
            placeholder={t('user_email')}
          />

          <Grid item xs={12}>
            <Table
              headers={[
                {
                  label: t('cra_user'),
                  sort: sortOrder.name,
                  onSort: (sort) => onSort('name', sort),
                },
                {
                  label: t('shared_on'),
                  sort: sortOrder.updatedAt,
                  onSort: (sort) => onSort('updatedAt', sort),
                },

                { label: t('action') },
              ]}
              cssClass={Style.manageAccessTable}
            >
              {filteredAndSortedList.map((c) => (
                <TableRow key={c.auth0UserId}>
                  <TableCell cellClass={Style.cell}>{c.name}</TableCell>
                  <TableCell cellClass={Style.cell}> {format(parseISO(c.updatedAt), twelveHourFormat)}</TableCell>
                  <TableCell cellClass={Style.cell}>
                    <Button
                      icon={deletingUser === c.auth0UserId ? undefined : 'person_remove'}
                      type='icon-round-secondary'
                      style={{ border: 'unset' }}
                      onClick={() => handleRevokeAccess(cra.id, c.auth0UserId, c.name)}
                      loading={deletingUser === c.auth0UserId}
                      cssClass={Style.revokeBtn}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </Grid>
        </Grid>
      </div>
    </RecallModal>
  )
}

export default ManageAccessModal
