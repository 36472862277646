import { useRecoilValueLoadable } from 'recoil'
import { AppFlags } from '../stores/AppStore'
import { IFlagDetails } from '../api/AppService'

const DEFAULT_RESPONSE = { enabled: false, client_filters: [] }

export const useShowFeature = (name: string): IFlagDetails => {
  const appFlags = useRecoilValueLoadable(AppFlags)

  if (appFlags.state !== 'hasValue' || !appFlags.contents) return DEFAULT_RESPONSE
  return appFlags.contents[name] || DEFAULT_RESPONSE
}
