export const downloadAction = (data: Blob, fileName?: string) => {
  const url = window.URL.createObjectURL(data)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName ?? 'file.txt')
  document.body.appendChild(link)
  link.click()
  link.parentNode?.removeChild(link)
}

export const convertJSONtoBlob = (obj: unknown) => {
  const str = JSON.stringify(obj)
  const bytes = new TextEncoder().encode(str)
  const blob = new Blob([bytes], {
    type: 'application/json;charset=utf-8',
  })

  return blob
}
