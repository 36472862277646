import ReactDOM from 'react-dom/client'
import App from './App'
import { Auth0Provider } from '@auth0/auth0-react'
import { AppState } from '@auth0/auth0-react/dist/auth0-provider'

import config from './config/config'

import './styles/index.sass'

require('./helpers/react-fixes')

import './helpers/i18n'

const onRedirectCallback = (appState?: AppState) => {
  window.location.href = appState?.targetUrl ? appState.targetUrl : '/'
}
const container = document.getElementById('root')

// Create a root.
const root = ReactDOM.createRoot(container!)

// Initial render
root.render(
  <Auth0Provider
    domain={config.AUTH0_DOMAIN}
    clientId={config.AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>,
)
