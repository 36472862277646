import { FC } from 'react'
import { FormInput } from '@aurecon-creative-technologies/styleguide'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import Style from '../../styles/common/RecallFormInput.module.sass'
import classNames from 'classnames'

export interface IRecallFormInputProps {
  chatType: number
  required?: boolean
  placeholder?: string
  label?: string
  error?: string
  value?: string
  onChange?: (s: string) => void
  cssClass?: string
  multiline?: boolean
  multilineLimit?: number
}

const RecallFormInput: FC<IRecallFormInputProps> = ({
  chatType,
  required,
  placeholder,
  label,
  error,
  value,
  onChange,
  cssClass,
  multiline,
  multilineLimit,
}) => {
  const chatTypeClasses = () => {
    return classNames(cssClass, {
      [Style.bamboo]: chatType === ChatTypeEnum.BAMBOO,
      [Style.cra]: chatType === ChatTypeEnum.CUSTOM_RECALL_APP,

      [Style.input]: !multiline,
      [Style.textarea]: multiline,
    })
  }

  return (
    <FormInput
      required={required}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      cssClass={chatTypeClasses()}
      label={label}
      error={error}
      multiline={multiline}
      multilineLimit={multilineLimit}
    />
  )
}

export default RecallFormInput
