import { useAuth0 } from '@auth0/auth0-react'
import { permissions } from '../config/permissions'
import { getUserRoles } from '../helpers/appRoles'

export const useUserHasPermissions = (action: number): boolean => {
  const { user } = useAuth0()
  const roles = getUserRoles(user)

  return !!roles.find((role) => permissions[action].includes(role))
}
