import { TokenExpiryWrapper } from './TokenManager'
import { getAsync } from '../helpers/apiRequest'
import { IResponse } from '../models/api/IResponse'
import { IGetSwaggerDef } from '../models/api/IGetSwaggerDefModel'

export const getSwaggerDef = TokenExpiryWrapper(
  (request: IGetSwaggerDef): Promise<IResponse<{ docs: string }>> => {
    return getAsync(`/${request.version}/app/api-docs`, request)
  },
  [],
  null,
)
