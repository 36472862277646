import { HttpContentType } from '../enums/ApiRequestConstants'
import { getAsync, postMultiFormDataAsync } from '../helpers/apiRequest'
import { IBambooChatResponseModel, IGenerateBambooResponseModel } from '../models/api/IBambooChatModel'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import {
  ICreateBambooChatRequestModel,
  IExportBambooResponseRequestModel,
  IGenerateBambooRequestModel,
  IGetBambooChatRequestModel,
} from '../models/api/ICreateBambooChatModel'
import { IResponse } from '../models/api/IResponse'
import { TokenExpiryWrapper } from './TokenManager'

export const processBambooFile = TokenExpiryWrapper(
  async (request: ICreateBambooChatRequestModel): Promise<IResponse<string>> => {
    try {
      const formData = new FormData()
      // insert attachments
      formData.append('file', request.file)
      formData.append('chatId', request.chatId)

      return await postMultiFormDataAsync('/v1/bamboo/upload', request.token ?? '', formData)
    } catch (error) {
      const response = error as Response
      return {
        success: false,
        message: await response.text(),
      }
    }
  },
  [],
  null,
)

export const generateBambooResponse = TokenExpiryWrapper(
  async (request: IGenerateBambooRequestModel): Promise<IResponse<IGenerateBambooResponseModel>> => {
    try {
      const formData = new FormData()
      // insert attachments
      const entities = request.entities.map((e) => ({
        ...e,
        properties: e.properties.map((p) => ({ ...p, matches: undefined })),
      }))
      formData.append('entities', JSON.stringify(entities))
      formData.append('fileContent', request.fileContent)
      formData.append('file', request.file || '')
      formData.append('chatId', request.chatId)
      formData.append('fileId', request.fileId ?? '')

      return await postMultiFormDataAsync('/v1/bamboo/generate', request.token ?? '', formData)
    } catch (error) {
      const response = error as Response
      return {
        success: false,
        message: await response.text(),
      }
    }
  },
  [],
  null,
)

export const getBambooChats = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<IBambooChatResponseModel[]>> => getAsync('/v1/bamboo', request),
  [],
  null,
)

export const getBambooChat = TokenExpiryWrapper(
  (request: IGetBambooChatRequestModel): Promise<IResponse<IGenerateBambooResponseModel>> =>
    getAsync(`/v1/bamboo/${request.chatId}`, request),
  [],
  null,
)

export const exportResponse = TokenExpiryWrapper(
  (request: IExportBambooResponseRequestModel): Promise<IResponse<Blob>> =>
    getAsync(`/v1/bamboo/export/${request.chatId}`, request, HttpContentType.BLOB),
  [],
  null,
)
