import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  FileUpload,
  FormInput,
  Grid,
  Icon,
  Loader,
  Pagination,
  Toggle,
  sizeUnits,
  useToast,
} from '@aurecon-creative-technologies/styleguide'
import { AppSlidePositionEnum } from '../enums/AppSlidePosition'
import { IAppSlide } from '../models/api/IHomepageModels'
import classNames from 'classnames'
import { useFileUploadConfig } from '../config/useFileUploadConfig'
import {
  APP_SLIDE_DESCRIPTION_MAX_LENGTH,
  APP_SLIDE_IMAGE_SIZE_MAX,
  APP_MINI_CARD_DESCRIPTION_MAX_LENGTH,
  JODIT_CONFIG,
  UI_MOBILE_WIDTH,
} from '../config/config'

import LanguageSwitch from './LanguageSwitch'
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { AppSlides, Language } from '../stores/AppStore'
import ConfirmModal from './modals/ConfirmModal'
import { useLanguages } from '../hooks/useLanguages'
import RecallJoditEditor from './common/RecallJoditEditor'

import { IErrorModel, getErrorsFromValidationResult } from '../validators/commonValidator'
import { appSlideLengthOnlySchema, appSlideSchema } from '../validators/appSlideValidator'
import { getTextFromHTML } from '../helpers/utils'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'

import Style from '../styles/AppSlideEditor.module.sass'
import { deleteAppSlide } from '../api/HomepageService'
import { ResponseData } from '../models/api/IResponse'
import { LanguageEnum } from '../enums/LanguageEnum'
import nextId from 'react-id-generator'
import { useMediaQuery } from 'react-responsive'

interface IAppSlideEditorProps {
  position: AppSlidePositionEnum
  onDirty: (slide: IAppSlide) => void
  onDelete: (slide: IAppSlide) => void
}

const AppSlideEditor: FC<IAppSlideEditorProps> = (props) => {
  const { position, onDelete } = props
  const AppSlidesLoader = useRecoilValueLoadable(AppSlides)
  const refreshAppSlides = useRecoilRefresher_UNSTABLE(AppSlides)

  const appLanguage = useRecoilValue(Language)
  const { t } = useLanguages()
  const { addToast } = useToast()

  const [language, setLanguage] = useState(appLanguage || 'en')
  const [sortOrder, setSortOrder] = useState(0)

  const [allSlidesForPosition, setAllSlidesForPosition] = useState<IAppSlide[] | null>(null)

  const [editing, setEditing] = useState<IAppSlide | null>(null)
  const [pageCount, setPageCount] = useState(1)

  const [removeCurrentSlide, setRemoveCurrentSlide] = useState(false)
  const [errors, setErrors] = useState<IErrorModel>({})
  const isDesktop = useMediaQuery({ minWidth: UI_MOBILE_WIDTH })

  useMemo(() => {
    if (AppSlidesLoader.state !== 'hasValue' || !AppSlidesLoader.contents) return
    const filteredSlides = AppSlidesLoader.contents.filter((slide) => slide.position === position)
    setAllSlidesForPosition(filteredSlides)
  }, [AppSlidesLoader.contents, AppSlidesLoader.state, position])

  useEffect(() => {
    if (!allSlidesForPosition) return

    if (editing) {
      const index = allSlidesForPosition.findIndex(
        (slide) =>
          slide.sortOrder === editing.sortOrder &&
          slide.language === editing.language &&
          slide.position === editing.position,
      )
      if (index !== -1) {
        allSlidesForPosition[index] = editing
      }
      if (editing.sortOrder === sortOrder && editing.language === language && editing.position === position) {
        return
      }
    }

    const foundOrder = allSlidesForPosition.filter((slide) => slide.sortOrder === sortOrder)
    const foundLanguage = foundOrder.find((slide) => slide.language === language)
    if (foundLanguage) {
      setEditing({
        ...foundLanguage,
        descriptionText: foundLanguage.description,
      })
      return
    }

    const addSlide = emptySlide(position, language, sortOrder)
    setAllSlidesForPosition((prev) => {
      if (prev) {
        return [...prev, addSlide]
      }
      return [addSlide]
    })
    setErrors({})

    setEditing(addSlide)
  }, [allSlidesForPosition, editing, language, position, sortOrder])

  const emptySlide = (position: AppSlidePositionEnum, language: string, sortOrder: number): IAppSlide => {
    return {
      position,
      language,
      sortOrder,
      fileSaS: '',
      url: '',
      title: '',
      subTitle: '',
      description: '',
      descriptionText: '',
      image: '',
      learnMore: false,
    }
  }

  const onLanguageChange = (value: string) => {
    setLanguage(value)
    setErrors({})
  }

  const hasEngSlide = useMemo(() => {
    if (editing?.language === LanguageEnum.ENGLISH) return false
    const slide = allSlidesForPosition?.find(
      (s) =>
        s.sortOrder === editing?.sortOrder &&
        s.position === editing.position &&
        s.position === position &&
        s.language === LanguageEnum.ENGLISH,
    )
    return !!slide
  }, [allSlidesForPosition, editing?.language, editing?.position, editing?.sortOrder, position])

  const onChange = (value: string | boolean, name: string) => {
    setEditing((prev) => {
      if (prev) {
        const newSlide = { ...prev, [name]: value }
        let errs = {}

        const toValidate = { ...newSlide, descriptionText: getTextFromHTML(newSlide.descriptionText ?? '') }
        const schema = !hasEngSlide ? appSlideSchema : appSlideLengthOnlySchema
        const validationResult = schema().validate(toValidate, { abortEarly: false })
        errs = getErrorsFromValidationResult(validationResult) || {}
        setErrors(errs)

        const newSlideWithError = { ...newSlide, error: errs }
        props.onDirty(newSlideWithError)

        return newSlideWithError
      }
      return null
    })
  }

  useEffect(() => {
    const toValidate = { ...editing, descriptionText: getTextFromHTML(editing?.descriptionText ?? '') }

    const schema = !hasEngSlide ? appSlideSchema : appSlideLengthOnlySchema
    const validationResult = schema().validate(toValidate, { abortEarly: false })
    const errs = getErrorsFromValidationResult(validationResult)
    setErrors(errs)
  }, [editing, hasEngSlide, position, props])

  const onBlur = () => {
    if (props.onDirty && editing) props.onDirty({ ...editing, error: errors })
  }

  useEffect(() => {
    if (allSlidesForPosition && editing) {
      const allLengths = Object.values(LanguageEnum).map((l) => {
        const slidesForLang = allSlidesForPosition.filter((a) => a.position === editing.position && a.language === l)

        if (!slidesForLang.length) return 0

        const length = slidesForLang.sort((a, b) => b.sortOrder - a.sortOrder)[0]?.sortOrder + 1

        return length
      })

      const length = Math.max(...allLengths)

      setPageCount(length)
    }
  }, [allSlidesForPosition, editing])

  const changePage = (page: number) => {
    setSortOrder(page - 1)
  }

  const handleFileChange = useCallback(
    (files: File[]) => {
      const newFile = files.length ? files[0] : null
      setEditing((prev) => {
        if (prev) {
          const newSlide = { ...prev, file: newFile, fileSaS: '', image: newFile?.name ?? '' }
          props.onDirty(newSlide)
          return newSlide
        }
        return null
      })
    },
    [props],
  )

  const sizeLimit = APP_SLIDE_IMAGE_SIZE_MAX
  const fileUnits = sizeUnits.MegaByte

  const fileCache = useRef<{ fileName: string; objUrl: string }[]>([])

  const renderIMG = (file?: File) => {
    if (!file) return <Icon size='64px' type='image' outlined cssClass='fileUpload' />

    const found = fileCache.current.find((cache) => cache.fileName === file?.name)
    if (found) {
      return <img src={found.objUrl} alt='preview' role='none' />
    }
    const objUrl = URL.createObjectURL(file)
    fileCache.current.push({ fileName: file?.name || '', objUrl })

    return <img src={objUrl} alt='preview' role='none' />
  }

  const fileUploadConfigArgs = {
    sizeLimit,
    fileUnits: fileUnits.label as keyof typeof sizeUnits,
    fileTypes: ['.jpg', '.jpeg', '.png'],
    file: editing?.file,
    fileUrl: editing?.fileSaS,
    onChange: handleFileChange,
    renderFile: renderIMG,
    Style,
    initialFiles: editing?.file ? [editing.file] : [],
  }

  const fileUploadProps = useFileUploadConfig(fileUploadConfigArgs)

  const slideClasses = classNames({
    [Style.appSlide]: true,
    [Style.main]: position === AppSlidePositionEnum.MAIN,
    [Style.left]: position === AppSlidePositionEnum.LEFT,
    [Style.right]: position === AppSlidePositionEnum.RIGHT,
  })

  const addNewSlide = () => {
    setSortOrder(pageCount)
    setPageCount((prev) => prev + 1)
  }

  const removeSlide = () => {
    setRemoveCurrentSlide(true)
  }

  const disableDelete = useMemo(() => {
    if (AppSlidesLoader.state !== 'hasValue' || !AppSlidesLoader.contents) return
    const hasSaved = AppSlidesLoader.contents?.find((a) => a.sortOrder === editing?.sortOrder)
    return !hasSaved
  }, [AppSlidesLoader.contents, AppSlidesLoader.state, editing?.sortOrder])

  const repositionSlideAfterDelete = () => {
    if (position !== AppSlidePositionEnum.MAIN) return

    const nextSlide = allSlidesForPosition?.find((s) => s.position === position && s.sortOrder === sortOrder + 1)
    const previousSlide = allSlidesForPosition?.find((s) => s.position === position && s.sortOrder === sortOrder - 1)

    if (nextSlide) return
    else if (previousSlide) setSortOrder(previousSlide.sortOrder)
  }

  const deleteSlide = async () => {
    if (!allSlidesForPosition) return
    if (editing) {
      const res = ResponseData(await deleteAppSlide({ sortOrder, position }))
      if (!res) {
        addToast({
          type: 'error',
          message: 'There was an issue deleting app slide.',
          timeout: 5000,
        })
        setRemoveCurrentSlide(false)

        return
      }

      addToast({
        type: 'success',
        message: 'App slide successfully removed',
        timeout: 5000,
      })

      const nonDeletedSlides = allSlidesForPosition
        .filter((s) => s.sortOrder !== editing.sortOrder)
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map((c, i) => ({ ...c, sortOrder: i }))

      setAllSlidesForPosition(nonDeletedSlides)
      setEditing(null)
      repositionSlideAfterDelete()
      refreshAppSlides()
      onDelete(editing)
    }
    setRemoveCurrentSlide(false)
  }

  const isUrlDisabled = (position: AppSlidePositionEnum, learnMore?: boolean) => {
    return position === AppSlidePositionEnum.MAIN && !learnMore
  }

  const getMaxDescriptionLength = (position: AppSlidePositionEnum) => {
    return position === AppSlidePositionEnum.MAIN
      ? APP_SLIDE_DESCRIPTION_MAX_LENGTH
      : APP_MINI_CARD_DESCRIPTION_MAX_LENGTH
  }

  return (
    <div className={classNames(Style.appSlideHolder, { [Style.appSlideHolderMobile]: !isDesktop })}>
      <ConfirmModal
        open={!!removeCurrentSlide}
        title={t('delete_slide_title')}
        message={t('delete_slide_desc')}
        labelYes={t('delete')}
        size='small'
        onClose={() => setRemoveCurrentSlide(false)}
        onSave={() => deleteSlide()}
        chatType={ChatTypeEnum.NONE}
      />

      {!allSlidesForPosition ? (
        <Loader label='loading' />
      ) : (
        <>
          <div className={slideClasses}>
            {position === AppSlidePositionEnum.MAIN && (
              <div className={Style.image}>
                {fileUploadProps?.initialFiles ? (
                  <FileUpload
                    key={nextId()}
                    {...fileUploadProps}
                    sizeLimit={[sizeLimit, fileUnits]}
                    height={`${fileUploadProps.height}px`}
                  />
                ) : (
                  <Loader label='loading image' />
                )}
              </div>
            )}

            <div className={Style.details}>
              <Grid row gap={12} cssClass={Style.form}>
                <Grid item xs={12}>
                  <FormInput
                    required
                    placeholder={'Enter mini title...'}
                    value={editing?.subTitle}
                    onChange={(value) => onChange(value, 'subTitle')}
                    onBlur={() => onBlur()}
                    label='Mini Title'
                    error={errors.subTitle?.[0]}
                    cssClass={Style.slideFormInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormInput
                    required
                    placeholder={'Enter title...'}
                    value={editing?.title}
                    onChange={(value) => onChange(value, 'title')}
                    onBlur={() => onBlur()}
                    label='Title'
                    error={errors.title?.[0]}
                    cssClass={Style.slideFormInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RecallJoditEditor
                    value={editing?.description ?? ''}
                    config={JODIT_CONFIG}
                    onBlur={(value) => {
                      onChange(value, 'description')
                      onBlur()
                    }} // preferred to use only this option to update the content for performance reasons
                    maxLength={getMaxDescriptionLength(position)}
                    onChange={(value) => onChange(value, 'descriptionText')}
                    error={errors.descriptionText?.[0]}
                    label='Description'
                    required
                  />
                </Grid>
                {position !== AppSlidePositionEnum.MAIN && (
                  <Grid item xs={12}>
                    <div className={Style.image}>
                      {fileUploadProps?.initialFiles ? (
                        <FileUpload
                          key={nextId()}
                          {...fileUploadProps}
                          sizeLimit={[sizeLimit, fileUnits]}
                          height={`${fileUploadProps.height}px`}
                        />
                      ) : (
                        <Loader label='loading image' />
                      )}
                    </div>
                  </Grid>
                )}
                {position === AppSlidePositionEnum.MAIN && (
                  <Grid item xs={12}>
                    <Toggle
                      label='Learn More Button'
                      value={editing?.learnMore}
                      cssClass={Style.toggleLearnMore}
                      onChange={(value) => {
                        onChange(value, 'learnMore')
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormInput
                    disabled={isUrlDisabled(position, editing?.learnMore)}
                    placeholder={'e.g. https://www.aurecongroup.com'}
                    value={editing?.url || ''}
                    onChange={(value) => onChange(value, 'url')}
                    onBlur={() => onBlur()}
                    label='URL'
                    error={errors.url?.[0]}
                    cssClass={Style.slideFormInput}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={Style.appSlideFooter}>
            {position === AppSlidePositionEnum.MAIN ? (
              <>
                <div>
                  <Button label='Add New Slide' size='small' type='primary' onClick={addNewSlide} />
                  <Button
                    icon='delete'
                    size='small'
                    type='icon-round'
                    default
                    onClick={() => removeSlide()}
                    disabled={pageCount === 1 || disableDelete}
                    cssClass={Style.deleteSlideBtn}
                  />
                </div>
                <div>
                  <LanguageSwitch value={editing?.language || language} onChange={onLanguageChange} />
                  <div className={Style.pageCount}>{`${sortOrder + 1} of ${pageCount}`}</div>
                  <Pagination page={sortOrder + 1} pageCount={pageCount} onChange={changePage} minimal smallChevron />
                </div>
              </>
            ) : (
              <div>
                <LanguageSwitch value={editing?.language || language} onChange={onLanguageChange} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default AppSlideEditor
