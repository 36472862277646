import { useEffect, useState } from 'react'
import { getNomicToken } from '../api/TokenManager'
import { getNomicAuthConfig } from '../api/AppService'
import { ChatTypeEnum } from '../enums/ChatTypeEnum'

export const useNomicToken = (chatType: number) => {
  const [nomicToken, setNomicToken] = useState('')
  const [loadingNomicToken, setLoadingNomicToken] = useState(false)

  useEffect(() => {
    const effectAsync = async () => {
      if (chatType !== ChatTypeEnum.CUSTOM_RECALL_APP) {
        setNomicToken('')
        setLoadingNomicToken(false)
        return
      }

      setLoadingNomicToken(true)
      const config = await getNomicAuthConfig({})

      if (!config?.data) {
        setLoadingNomicToken(false)
        return
      }

      const nomicToken = await getNomicToken([], config.data)
      setNomicToken(nomicToken ?? '')
      setLoadingNomicToken(false)
    }

    effectAsync()
  }, [chatType])

  return { loadingNomicToken, nomicToken }
}
