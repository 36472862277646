import { ValidationResult } from 'joi'

export interface IErrorModel {
  [key: string]: string[] | undefined
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getErrorsFromValidationResult = (result: ValidationResult<any>) => {
  const errors: IErrorModel = {}
  result.error?.details.forEach((d) => {
    const key = d.context?.key ?? ''
    const errorExistingMessages = errors[key]
    errors[key] = errorExistingMessages ? [...errorExistingMessages, d.message] : [d.message]
  })
  return errors
}
