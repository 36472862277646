import { FC } from 'react'

import Style from '../styles/PageHeader.module.sass'

interface IPageHeaderProps {
  pageTitle?: string
}

const PageHeader: FC<IPageHeaderProps> = (props) => {
  if (!props.pageTitle) return null

  return (
    <div className={Style.pageHeader}>
      <div className={Style.pageTitle}>{props.pageTitle}</div>
    </div>
  )
}

export default PageHeader
