import Joi from 'joi'
import { IBambooPropertyModel } from '../models/IBambooPropertyModel'
import { TFunction } from 'i18next'

export const BambooPropertyModalFields = {
  name: 'name',
  description: 'description',
  type: 'type',
  required: 'required',
}

export const bambooPropertyModalSchema = (extProps: { properties: IBambooPropertyModel[]; t: TFunction }) => {
  const { properties, t } = extProps

  return Joi.object({
    [BambooPropertyModalFields.name]: Joi.string()
      .trim()
      .min(1)
      .max(500)
      .required()
      .custom((text, helper) => {
        if (properties?.some((p) => p.name.toLowerCase() === text.toLowerCase().trim()))
          return helper.message({ custom: t('bamboo_err') })

        return true
      })
      .messages({ 'string.empty': t('bamboo_err2') }),
    [BambooPropertyModalFields.description]: Joi.string().allow('').max(1000),
  })
}
