import { FC, useEffect, useState } from 'react'
import { TokenManager } from '../api/TokenManager'
import { Container, Button } from '@aurecon-creative-technologies/styleguide'

import Page from '../components/Page'
import LoadingScreen from '../components/LoadingScreen'
import { appInsights } from '../api/AppInsights'
import { useLanguages } from '../hooks/useLanguages'

import Style from '../styles/Login.module.sass'

interface IConsentProps {
  setConsented: () => void
}

/* Page which allows user to initiate consent process */
const Consent: FC<IConsentProps> = ({ setConsented }) => {
  const [checkingConsent, setCheckingConsent] = useState(true)
  const { t } = useLanguages()

  if (appInsights) appInsights.trackPageView({ name: 'Consent' })

  async function consentProcess() {
    try {
      const tokenMgr = await TokenManager()
      await tokenMgr.initiateConsentPopup()
      setConsented()
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    async function runEffect() {
      const tokenMgr = await TokenManager()
      const token = await tokenMgr.requestToken([])
      if (token === 'consent_required') {
        setCheckingConsent(false)
      } else {
        setConsented()
      }
    }
    runEffect()
  })

  return (
    <Page>
      <Container>
        {checkingConsent ? (
          <LoadingScreen text={t('access_message')} />
        ) : (
          <div className={Style.login}>
            <h1>Consent is required</h1>
            <Button label='Access consent' onClick={consentProcess} />
          </div>
        )}
      </Container>
    </Page>
  )
}

export default Consent
