import { FC } from 'react'
import { Icon } from '@aurecon-creative-technologies/styleguide'

import Style from '../styles/History.module.sass'

const EmptyFilteredHistory: FC = () => {
  return (
    <div className={Style.message}>
      <p>
        <Icon type='search_off' size='60px' />
      </p>
      <p>No chats match your search.</p>
    </div>
  )
}

export default EmptyFilteredHistory
