import { ContentTypeToRealFileExtn, FileMimeType } from '../enums/FileTypeEnum'

export const SUPPORT_EMAIL = 'recall.support@aurecongroup.com'
export const JIRA_SUPPORT_URL = 'https://aurecondigital.atlassian.net/servicedesk/customer/portal/29'
export const ABOUT_URL = 'https://aurecongroup.sharepoint.com/sites/Hive-Digital/SitePages/AI-at-Aurecon.aspx'
export const REQUEST_ACCESS_URL = 'https://aurecondigital.atlassian.net/servicedesk/customer/portal/29'

export const PDF_EXT = [ContentTypeToRealFileExtn[FileMimeType.PDF]]
export const TXT_EXT = [ContentTypeToRealFileExtn[FileMimeType.TXT]]
export const IMAGE_EXT = [
  ContentTypeToRealFileExtn[FileMimeType.JPG],
  ContentTypeToRealFileExtn[FileMimeType.JPEG],
  ContentTypeToRealFileExtn[FileMimeType.PNG],
]
export const OFFICE_EXT = [
  ContentTypeToRealFileExtn[FileMimeType.PPTX],
  ContentTypeToRealFileExtn[FileMimeType.PPT],
  ContentTypeToRealFileExtn[FileMimeType.DOCX],
  ContentTypeToRealFileExtn[FileMimeType.DOC],
  ContentTypeToRealFileExtn[FileMimeType.XLSX],
  ContentTypeToRealFileExtn[FileMimeType.XLS],
  ContentTypeToRealFileExtn[FileMimeType.CSV],
]

export const ATTACHMENT_EXT = [...PDF_EXT, ...TXT_EXT, ...IMAGE_EXT]
export const ATTACHMENT_FILETYPES = [
  FileMimeType.PDF,
  FileMimeType.TXT,
  FileMimeType.JPG,
  FileMimeType.JPEG,
  FileMimeType.PNG,
]

export const MAX_ATTACHMENT_SIZE = 1024 * 1024 * 20 // 20MB
export const MAX_NO_ATTACHMENTS = 3

export const MAX_FILE_NAME_LENGTH = 80

export const BAMBOO_FILE_SIZE_LIMIT = 20

export const STREAM_SEPERATOR = '\n*#*\n'
export const TEMP_ROW_KEY = 'temporary_chat_id'
export const UI_MOBILE_WIDTH = 576
export const UI_WIDTH_COLLAPSE = 770
export const UI_FULL_WIDTH = 1200

export const FILE_VIEWER_HEIGHT_FULL_WIDTH = 400
export const FILE_VIEWER_HEIGHT_DESKTOP = 250
export const FILE_VIEWER_HEIGHT_MOBILE = 150

export const DROPZONE_HEIGHT = 400
export const DROPZONE_WIDTH = '100%'

export const APP_SLIDE_IMAGE_SIZE_MAX = 1
export const APP_SLIDE_DESCRIPTION_MAX_LENGTH = 250
export const APP_MINI_CARD_DESCRIPTION_MAX_LENGTH = 150
export const SYSTEMS_BANNER_MAX_LENGTH = 500

export const TOOLTIP_NO_OFFSET = { top: 0, left: 0 }

export const USER_GUIDE_URL =
  'https://aurecongroup.sharepoint.com/sites/Hive-Digital/SitePages/Recall.aspx#frequently-asked-questions-%28related-to-recall%29'

export const COOKIE_FULLSCREEN = 'air-fullscreen'
export const COOKIE_LANGUAGE = 'recall-language'
export const COOKIE_LOGIN_LANGUAGES = 'recall-login-languages'

export const USER_OID = 'https://aurecon.com/oid'

const config = {
  AUTH0_DOMAIN: 'auth.aurecongroup.digital',
  AUTH0_CLIENT_ID: 'pgbudCuOef72sYdmIiBKxJ7seU8OUF39',
  AUTH0_AUDIENCE: 'https://recall.aurecongroup.digital',
  AUTH0_LOGOUT_RETURN: window.location.origin,
  AUTH0_ROLES: 'https://aurecongroup.com/roles',
  MOBLIE_VIEW_MAX_WIDTH: 768,
}

export const PDF_GLOBAL_WORKER = '//unpkg.com/pdfjs-dist@{{package-version}}/build/pdf.worker.min.mjs'

export const LOCAL_CORS = ['localhost', '127.0.0.1']

export const twelveHourFormat = 'dd/MM/yyyy hh:mm a'

export const JODIT_CONFIG = {
  readonly: false,
  buttons: 'bold,italic,underline,strikethrough,fontsize,paragraph,lineHeight,superscript,subscript',
  statusbar: false,
  showTooltip: false,
  sizeLG: 12,
  sizeSM: 12,
  sizeMD: 12,
  disablePlugins: 'add-new-line',
}

export default config
