export enum actions {
  VIEW_DASHBOARD = 1,
  EDIT_HOMEPAGE = 2,
  EDIT_BANNER = 3,
}

export enum Role {
  FeedbackViewer = 'Recall Feedback Viewer',
  BannerAdmin = 'Recall::dev::bannerAdmin',
  HomeAdmin = 'Recall::dev::homePublisher',
}

export const permissions = {
  [actions.VIEW_DASHBOARD]: [Role.FeedbackViewer],
  [actions.EDIT_HOMEPAGE]: [Role.HomeAdmin],
  [actions.EDIT_BANNER]: [Role.BannerAdmin],
}
