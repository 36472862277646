import { FC, useRef } from 'react'
import { Button } from '@aurecon-creative-technologies/styleguide'
import { appInsights } from '../api/AppInsights'
import Page from '../components/Page'

import Style from '../styles/StaticPage.module.sass'

const CookiesPolicy: FC = () => {
  const contentContainerRef = useRef<HTMLDivElement>(null)

  if (appInsights) appInsights.trackPageView({ name: 'TermsCookies' })

  const handleBackToTop = () => {
    if (!contentContainerRef.current) return
    contentContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <Page menu contentWrapper>
      <div className={Style.staticContent} ref={contentContainerRef}>
        <h1>Cookies Policy</h1>
        <br />
        <p>
          <strong>Consent</strong>
        </p>
        <p>
          We use cookies on Recall <sup>TM</sup> and by using the application you consent to the use of accordance with
          Aurecon's Cookies Policy.
        </p>
        <p>
          This policy explains what cookies are, how we use cookies, how third-party applications we use may use
          cookies, your choices regarding cookies and further information about cookies.
        </p>
        <br />
        <p>
          <strong>What are cookies?</strong>
        </p>
        <p>
          Cookies are text files containing small pieces of information sent by your web browser by a website you visit.
          A cookie file is stored inside your web browser and allows the website or a third party to recognise you and
          make your next visit easier and the website more useful to you.
        </p>
        <br />
        <p>
          <strong>Types of cookies used by Aurecon</strong>
        </p>
        <p>Cookies can be "persistent" or "session" cookies and Aurecon uses both.</p>
        <p>
          Session cookies are temporary whilst you are browsing and are deleted automatically when you close your
          browser.
        </p>
        <p>
          Persistent cookies are not temporary and remain on your device after you close your browser. This helps us
          remember your user preferences when you return to our site.
        </p>
        <p>A full list of cookies is available on request.</p>
        <br />
        <p>
          <strong>How Aurecon uses cookies</strong>
        </p>
        <p>
          When you use and access Recall <sup>TM</sup> , we may place a number of cookies files in your web browser.
        </p>
        <br />
        <p>
          <strong>We use cookies to:</strong>
        </p>
        <ul>
          <li>Enable certain functions of our website</li>
          <li>Provide analytics</li>
          <li>Collect certain information about your browsing</li>
          <li>Store your preferences</li>
          <li>
            Share information with companies within the Aurecon group and their partners to enable advertisements
            delivery
          </li>
        </ul>
        <br />
        <p>
          <strong>Third party cookies</strong>
        </p>
        <p>
          In addition to our own cookies, we may also use various third-party cookies to, for example, report usage
          statistics of the website or deliver advertisements on and through the website.
        </p>
        <br />
        <p>
          <strong>What are your choices regarding cookies</strong>
        </p>
        <p>
          If you would like to delete cookies, or instruct your web browser to delete or refuse cookies, please visit
          the help page of the particular web browser you are using for instructions.
        </p>
        <p>
          Please note, however, that if you disable cookies or refuse to accept them, you may not be able to use all the
          features we offer. For example, you may not be able to store your preferences, and some of our pages might not
          display properly.
        </p>
        <br />
        <p>
          <strong>Where can you find more information about cookies?</strong>
        </p>
        <p>
          You can learn more about cookies at:{' '}
          <a href='https://www.allaboutcookies.org'>https://www.allaboutcookies.org</a>
        </p>
        <br />
        <p>
          <strong>Changes to the Cookies Policy</strong>
        </p>
        <p>This Cookies Policy may be updated from time to time and was last updated in November 2017.</p>
        <br />
        <p>
          <strong>Cookies policy does not cover third party websites</strong>
        </p>
        <p>
          Aurecon's Cookies Policy does not apply to, and we are not responsible for, the privacy and compliance
          practices of third party websites which may be linked to or accessible through Aurecon's website
        </p>
        <div className={Style.backToTopBtn}>
          <Button type='secondary' label='Back to top' onClick={handleBackToTop} />
        </div>
      </div>
    </Page>
  )
}

export default CookiesPolicy
