import { FC, useEffect, useMemo, useState } from 'react'
import { Button, IButtonProps, Modal } from '@aurecon-creative-technologies/styleguide'
import { useRecoilState, useRecoilValue } from 'recoil'
import { CurrentQuestion, ShowEditHistoryModal } from '../../stores/AppStore'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import Style from '../../styles/EditHistoryModal.module.sass'

const EditHistoryModal: FC = () => {
  const currentQuestion = useRecoilValue(CurrentQuestion)
  const [showEditHistoryModal, setShowEditHistoryModal] = useRecoilState(ShowEditHistoryModal)
  const [answerIndex, setAnswerIndex] = useState(0)

  const currentAnswer = currentQuestion?.answers && answerIndex === currentQuestion.answers.length - 1

  useEffect(() => {
    if (!currentQuestion?.answers) return

    setAnswerIndex(currentQuestion.answers.length - 1)
  }, [currentQuestion])

  const answer = useMemo(() => {
    if (!currentQuestion?.answers?.[answerIndex]) return ''
    return currentQuestion?.answers[answerIndex].answer || ''
  }, [answerIndex, currentQuestion])

  const handleModalClose = () => {
    setAnswerIndex(0)
    setShowEditHistoryModal(false)
  }

  const handlePrevAnswer = () => {
    if (!answerIndex) return

    setAnswerIndex(answerIndex - 1)
  }

  const handleNextAnswer = () => {
    if (currentAnswer) return

    setAnswerIndex(answerIndex + 1)
  }

  const modalActions = [
    {
      label: 'Close',
      type: 'secondary',
      onClick: handleModalClose,
    },
  ] as IButtonProps[]

  return (
    <Modal isShowing={showEditHistoryModal} onClose={handleModalClose} actions={modalActions} size='large'>
      <h2>Answer Edit History</h2>
      <div className={Style.navigation}>
        <Button
          type='icon-square'
          icon='chevron_left'
          size='small'
          onClick={handlePrevAnswer}
          disabled={!answerIndex}
        />
        <Button
          type='icon-square'
          icon='chevron_right'
          size='small'
          onClick={handleNextAnswer}
          disabled={currentAnswer}
        />
        Edited answer: {answerIndex + 1} ({!answerIndex ? 'Original response' : 'Edited'}
        {currentAnswer && ' current'})
      </div>
      <div className={Style.content}>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{answer}</ReactMarkdown>
      </div>
    </Modal>
  )
}

export default EditHistoryModal
