import { FeedbackTypeEnum, FeedbackTypeToLabel } from '../enums/FeedbackTypeEnum'

export const feedbackTypes = (feedbackType: number) => {
  const type = feedbackType || 0

  const feedback: string[] = []
  const feedbackNumber: number[] = []
  Object.keys(FeedbackTypeEnum).forEach((ft) => {
    const bit = (type & FeedbackTypeEnum[ft]) === FeedbackTypeEnum[ft]

    if (!!FeedbackTypeEnum[ft] && bit) {
      feedback.push(FeedbackTypeToLabel[FeedbackTypeEnum[ft]])
    }
  })

  return { feedback, feedbackNumber }
}
