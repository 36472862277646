import { FC } from 'react'
import { useRecoilValue } from 'recoil'
import { Modal } from '@aurecon-creative-technologies/styleguide'
import { useLanguages } from '../../hooks/useLanguages'

import { ShowPreparingChat } from '../../stores/AppStore'

const PreparingChatModal: FC = () => {
  const showPreparingChat = useRecoilValue(ShowPreparingChat)
  const { t } = useLanguages()

  const handleModalClose = () => {
    // do nothing
  }

  return (
    <Modal isShowing={showPreparingChat} isCloseButton={false} onClose={handleModalClose}>
      <h2>{t('processing')}</h2>
      <p>{t('prep_new_chat')}</p>
    </Modal>
  )
}

export default PreparingChatModal
