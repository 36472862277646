import { FC, useState } from 'react'
import RecallModal from '../common/RecallModal'
import { FileUpload, sizeUnits } from '@aurecon-creative-technologies/styleguide'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import { FullScreen } from '../../stores/AppStore'
import { useRecoilValue } from 'recoil'
import PDFViewer from '../PDFViewer'
import { useLanguages } from '../../hooks/useLanguages'

import { BAMBOO_FILE_SIZE_LIMIT, FILE_VIEWER_HEIGHT_DESKTOP } from '../../config/config'
import { useFileUploadConfig } from '../../config/useFileUploadConfig'

import Style from '../../styles/ManageFileModal.module.sass'
import nextId from 'react-id-generator'

interface IManageFileModalProps {
  open: boolean
  file: File | null
  viewerId?: string
  onClose: () => void
  onUpdate: (file: File) => void
}

const ManageFileModal: FC<IManageFileModalProps> = ({ open, file, onClose, onUpdate }) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const fullScreen = useRecoilValue(FullScreen)

  const { t } = useLanguages()
  const labelYes = t('update')

  const onModalClosed = () => {
    onClose()
  }

  const handleUpdate = () => {
    if (uploadedFile) {
      onUpdate(uploadedFile)
    }
  }

  const handleFileChange = (files: File[]) => {
    const newFile = files.length ? files[0] : null
    setUploadedFile(newFile)
  }
  const renderPDFViewer = (renderFile?: File) => {
    return (
      <PDFViewer
        file={URL.createObjectURL(renderFile as File)}
        showOnlyAsPreview={false}
        height={fileUploadProps?.height || FILE_VIEWER_HEIGHT_DESKTOP}
        viewerId={`viewer-manage-${renderFile?.name}-${nextId()}`}
      />
    )
  }

  const sizeLimit = BAMBOO_FILE_SIZE_LIMIT
  const fileUnits = sizeUnits.MegaByte
  const fileUploadProps = useFileUploadConfig({
    sizeLimit,
    fileUnits: fileUnits.label as keyof typeof sizeUnits,
    fileTypes: ['.pdf'],
    file,
    onChange: handleFileChange,
    renderFile: renderPDFViewer,
    Style,
  })

  return (
    <RecallModal
      isShowing={open}
      onClose={onModalClosed}
      chatType={ChatTypeEnum.BAMBOO}
      disabled={!uploadedFile}
      onSave={handleUpdate}
      labelYes={labelYes}
      size={fullScreen ? 'medium' : 'small'}
    >
      <div className={Style.uploadArea}>
        <h3>{t('upload_file')}</h3>
        <p className={Style.uploadSubtitle}>{t('upload_desc')}</p>
        <FileUpload {...fileUploadProps} sizeLimit={[sizeLimit, fileUnits]} height={`${fileUploadProps?.height}px`} />
      </div>
    </RecallModal>
  )
}

export default ManageFileModal
