import { FC, useCallback, useEffect, useMemo } from 'react'
import { getDocument, GlobalWorkerOptions, PDFDocumentProxy, version } from 'pdfjs-dist'
import Style from '../styles/PDFViewer.module.sass'
import { PDF_GLOBAL_WORKER } from '../config/config'
import classNames from 'classnames'

interface IPDFViewer {
  file: string
  showOnlyAsPreview: boolean
  width?: number
  height?: number
  viewerId?: string
  onReady?: (pageNums: number) => void
}

GlobalWorkerOptions.workerSrc = PDF_GLOBAL_WORKER.replace('{{package-version}}', version)

const PDFViewer: FC<IPDFViewer> = (props) => {
  const { file, showOnlyAsPreview, height, width, viewerId, onReady } = props
  const pdfViewerId = useMemo(() => viewerId ?? 'pdf-viewer-container', [viewerId])

  const viewerHeight = useMemo(() => height ?? window.innerHeight, [height])
  const renderPage = useCallback(
    async (pageNum: number, pdf: PDFDocumentProxy) => {
      const page = await pdf.getPage(pageNum)
      const scale = page.view[2] / page.view[3]
      const canvasId = `pdf-page-${pageNum}-${pdfViewerId}`
      let canvas = document.getElementById(canvasId) as HTMLCanvasElement

      if (canvas) {
        canvas.remove()
      }
      canvas = document.createElement('canvas')
      canvas.id = canvasId
      canvas.className = Style.pdfPageContainer

      canvas.width = viewerHeight * scale
      canvas.height = viewerHeight

      const viewport = page.getViewport({ scale: canvas.width / page.getViewport({ scale: 1 }).width })

      canvas.width = viewport.width
      canvas.height = viewport.height
      const context = canvas.getContext('2d')

      if (!context) return

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      }

      const pdfViewer = document.getElementById(pdfViewerId)
      pdfViewer?.appendChild(canvas)
      page.render(renderContext)
    },
    [viewerHeight, pdfViewerId],
  )

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const loadingTask = getDocument(file)
        const pdf = await loadingTask.promise

        onReady?.(pdf.numPages)
        if (pdf.numPages > 0) {
          for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
            renderPage(pageNum, pdf)
            if (showOnlyAsPreview && pageNum === 1) break
          }
        }
      } catch (error) {
        console.error('Error loading PDF:', error)
      }
    }
    fetchPdf()
  }, [file, renderPage, onReady, showOnlyAsPreview])

  const viewerClasses = () => {
    return classNames({
      [Style.pdfViewerContainer]: true,
      [Style.showOnlyAsPreview]: showOnlyAsPreview,
    })
  }

  return <div id={pdfViewerId} className={viewerClasses()} style={{ maxHeight: viewerHeight - 2, width: width }} />
}

export default PDFViewer
